import logo from "../images/logo.svg";
import { BackSvg, ExitSvg } from "../../svg";
import { useContext } from "react";
import { MainContext } from "../../ContextState";
import ProgressBar2 from "./ProgressBar2";
import { STAGES_STEPS } from "../../common/dataMaps";
import styled from "styled-components";
import { getTokenCookie, removeTokenCookie } from "../../common/cookie";

const ExitStyled = styled.div`
    position: absolute;
    right: 250px;
    top: -9px;
    cursor: pointer;

    @media (max-width: 767px) {
        right: 10px;
        top: 51px;
    }

    svg {
        width: 24px;
        height: 24px;
    }
`;

function Header({ loading, showLogo, langData, isBookReady, setFocusToProgress }) {
    const { stageNumberContext } = useContext(MainContext);
    const [currentStage, s_currentStage] = stageNumberContext;
    const goBack = () => {
        switch (currentStage) {
            case STAGES_STEPS.TEMPLATE:
                s_currentStage(STAGES_STEPS.INFO);
                break;
            case STAGES_STEPS.INFO:
                s_currentStage(STAGES_STEPS.MEDIA);
                break;
            case STAGES_STEPS.OTP:
                s_currentStage(STAGES_STEPS.MEDIA);
                break;
            default:
                break;
        }
    };

    let showBackButton = true;
    if (currentStage === STAGES_STEPS.MEDIA) {
        showBackButton = false;
    }
    if (showBackButton && isBookReady) {
        showBackButton = false;
    }

    const token = getTokenCookie();

    let showExit = false;
    if (token && currentStage !== STAGES_STEPS.MEDIA) {
        showExit = true;
    }

    return (
        <div className="header">
            <ProgressBar2
                langData={ langData }
                setFocusToProgress={ setFocusToProgress }
            />
            { showLogo && (
                <div className="logo-container">
                    <div className="logo"><img src={ logo } alt="logo"/></div>
                </div>
            ) }
            { showBackButton && (
                <button
                    aria-label={ langData.arias.back_button }
                    // tabIndex="0"
                    className="back-btn" onClick={ () => goBack() }>
                    <BackSvg/>
                </button>
            ) }
            { showExit && (
                <ExitStyled
                    onClick={ () => {
                        removeTokenCookie();
                        localStorage.removeItem("bookId");
                        localStorage.removeItem("age");
                        localStorage.removeItem("faceDataId");
                        localStorage.removeItem("imageBlob");
                        localStorage.removeItem("name");
                        localStorage.removeItem("phone");
                        window.location.reload();
                    } }
                >
                    <ExitSvg/>
                </ExitStyled>
            ) }
        </div>
    );
}

export default Header;
