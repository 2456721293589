import React from 'react';
import styled from "styled-components";
import Lottie from 'react-lottie-player'

// import animation from '../images/magic-book.json';
// import animation from '../images/magic-wand.json';
import animation from '../images/circle.json';

const WrapperStyled = styled.div`

`;

const Loader = ({ariaLabel, height=150, width=150}) => {
    return (
        <WrapperStyled
            className="loader-o"
            aria-label={ariaLabel}
        >
            <Lottie
                loop
                animationData={animation}
                play
                style={{width: width, height: height, margin: "auto"}}
            />
        </WrapperStyled>
    );
};

export default Loader;