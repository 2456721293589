const heb = () => {
    return {
        image_errors: {
            6000: "לא ניתן לנתח תמונת פנים",
            6001: "לא הצלחנו לזהות פרצוף בתמונה",
            6002: "זיהינו יותר מפרצוף אחד בתמונה",
            6003: "זיהינו פנים חסומות",
            6004: "זיהינו שהעיניים סגורות",
            6005: "זיהינו בתמונה משקפי שמש",
            6006: "זיהינו שהפרצוף בתמונה מוטה הצידה או למעלה",
            6007: "זיהינו פנים קטנות מדי",
        },
        image_error_labels: {
            6000: "לא ניתן לנתח תמונת פנים",
            6001: "תמונה ללא פנים",
            6002: "כמה פרצופים",
            6003: "פנים מכוסות",
            6004: "עיניים עצומות",
            6005: "זיהינו בתמונה משקפי שמש",
            6006: "ראש מוטה מעלה",
            6007: "תמונה מרוחקת",
            "normal": "צילום ישר וברור"
        },
        arias: {
            stepper: {
                start: "הינך נמצא בשלב",
                end: "מתוך"
            },
            back_button: "אחורה",
            cover_selection: "כריכה מספר",
            image_to_load: "העלאת תמונה",
            image_load: "מעלה תמונה",
            loaded_image: "תמונה שהועלתה",
            customer_image: "תמונת הלקוח",
            exampleDialogTitle: "דוגמא של תמונות",
            payDialogTitle: "יש לבחור סוג ספר",
            templateInfoDialogTitle: "מידע על הטמפליט",
            next: "קודם",
            prev: "הצג אפשרויות נוספות",
            choose_template: "רשימה של נושאים",
            close: "סגור",
            more_info_about_template: "הצג מידע נוסף לנושא",
            levels: {
                level: "והנך צריך",
                upload_image: "לעלות תמונה",
                fill_info: "למלא נתונים רלוונטיים עבור הלקוח",
                choose_appearance: "לבחור מראה",
                choose_template: "לבחור תבנית",
            },
            book_image_preview: "תמונת עמוד",
            preview_book: {
                cover: "בחר תמונת כריכה",
                image: "דוגמה לדף תמונה",
                text: "דוגמה לדף טקסט",
                mini_image: "דוגמה לתמונה מספר"
            },
            name_validation: "אימות שם"
        },
        please_upload_image: "נא להעלות תמונה",
        general: {
            next: "ממשיכים",
            pageTitle: "יצירת ספר",
            try_again: "נסה שוב"
        },
        error: {
            error_upload: "עלייך לבחור תמונה כדי להמשיך",
            error_stage: "עלייך למלא את כל הפרטים כדי להמשיך",
            error_creation: "הייתה בעיה ביצירת הספר..",
            error_template: "עלייך לבחור טמפלט לפני שאתה ממשיך ליצירת הספר!",
            many_faces: "היי, זיהינו יותר מפרצוף אחד בתמונה, נסו שוב"
        },
        stage1: {
            image_is_here: "העלו תמונה איכותית",
            image_desc_1: "כדי להעלות תמונה, גררו אותה לתוך",
            image_desc_2: "המסך או לחצו על הכפתור",
            several_things: "דוגמאות לתמונה נכונה!",
            browse_files: "עיין בקבצים",
            or: "או",
            several_things_cont: {
                "first": "העלו תמונה איכותית של הילד/ה בה הם נמצאים במרכז",
                "second": "2. דוגמאות לתמונה נכונה"
            },
            title: "יאללה, מתחילים!",
            explain: "העלו תמונה איכותית של הילד בה הוא נמצא במרכז הפריים...\n" +
                "         כדי להעלות תמונה, גררו אותה לתוך המסך או לחצו על הכפתור הכתום.",
            file_size: "מקסימום גודל:",
            note: "תמונה איכותית וממוקדת משפרת את התוצאה - נסו לבחור תמונה ברורה שבה הדמות ניצבת במרכז ובמוקד",
            example: "תראו לי דוגמא",
            click_here: "לחץ/י כאן",
            popup_bullets: {
                bullet_one: "התמונה צריכה להכיל פרצוף אחד, מומלץ ללא אלמנטים המסתירים את הפנים כמו תחפושת, מסיכה או משקפיים כהות",
                bullet_two: "הפנים חשובות מאד לתהליך, לכן השתדלו להעלות תמונה מלאה של הפנים עם הפוקוס עליהם במרכז",
                bullet_three: "התמונה צריכה להיות עם עיניים פקוחות",
            },
            popup_exit: "אני מבין",
            cropper_text: "מקמו את פני ילדכם במסגרת לתוצאות הטובות ביותר",
        },
        stage2: {
            nice_photo: "וואו! תמונה מעולה!",
            continue_text: "ספרו לנו על הילד/ה שלכם",
            name_validation_1: "רק מוודאים האם השם הפרטי הוא",
            name_validation_2_male: "?",
            name_validation_2_female: "?",
            yes: "כן",
            no: "לא",
            fields: {
                age_hero: "גיל",
                name_male: "שם הילד (פרטי בלבד)",
                name_female: "שם הילדה (פרטי בלבד)",
                gender: {
                    male: "בן",
                    female: "בת"
                },
                age: "הגיל שלך הוא:",
                apply: "שמור",
                email: "מייל",
                phone: "מספר טלפון",
                email_validation: "מייל לא תקין",
                sex: "מין",
                age_validation: "טווח הגילאים הינו",
            },
            terms: "אני מאשר/ת את ",
            terms_part_2: "תנאי השימוש והצהרת הפרטיות",
            terms_link: "https://mebook.ai/%d7%9e%d7%93%d7%99%d7%a0%d7%99%d7%95%d7%aa-%d7%a8%d7%9b%d7%99%d7%a9%d7%95%d7%aa-%d7%95%d7%94%d7%97%d7%96%d7%a8%d7%95%d7%aa/",
            terms_sms: "אני מאשר/ת לשלוח דיוור באמצעות הודעות טקסט ו/או דואר אלקטרוני",
        },
        stage3: {
            kids_name_male: "שם הגיבור שלנו הוא: ",
            kids_name_female: "שם הגיבורה שלנו הוא: ",
            kids_age: "והוא בן: ",
            kids_age_female: "והיא בת: ",
            hairColor: "צבע שיער",
            eyeColor: "צבע עיניים",
            skinColor: "צבע עור",
            hairStyle: "סגנון שיער"
        },
        stage4: {
            title: "בוחרים נושא",
            buttonText: "לוחצים והקסם קורה",
        },
        loading_texts: {
            text1: "ששש….מפיקים ספר פה .תמתינו כמה רגעים ואל תסגרו את העמוד",
            subtext1: "רק כמה רגעים קוסמיים ואנחנו שם",
            text2: "הכנסנו את הספר לתנור",
            subtext2: "ההרפתקה האישית שלך בדרך..",
            text3: "יוצרים את הסיפור שלך",
            subtext3: "הספר שלך בדרך אלייך!",
            text4: "מפזרים אבקת קסמים",
            subtext4: "ההרפתקה האישית שלך בדרך..",
            text5: "בקרוב תקבלו ספר מופלא",
            subtext5: "אנחנו מכינים סיפור רק בשבילך!",
        },
        click_me: "לחץ עלי",
        end_process: {
            main_text: "מדהים! בחר/י את הכריכה!",
            buttonText: "למעבר לתשלום",
            choose_cover_pdf: "ספר דיגיטלי",
            choose_cover_hard: "ספר מודפס",
            p_description_hard: "ספר מודפס בכריכה קשה והדפסה איכותית, שידהים את כל מי שיקבל אותו!",
            p_description_pdf: "ספר אלקטרוני דיגיטלי באיכות גבוהה בפורמט PDF, מעוצב להפליא וניתן לקריאה מכל מקום ובכל זמן.",
            price_pdf: "25 ש״ח",
            price_book: "127 ש״ח + משלוח"
        },
        stage5: {
            banned_title: "על מנת להבטיח את זמינות השירות לכלל המשתמשים, ישנה מגבלה על כמות יצירת ספרים באופן חלקי.",
            desc: "על מנת להמנע ממגבלה זו, תוכלו להמשיך את תהליך יצירת אחד הספרים שכבר התחלתם בעבר."
        },
        example_of_first_page: "* דוגמה לעמוד ראשון",
        do_not_refresh_the_page: "נא לא לרענן את העמוד",
        continue_editing_book: "להמשך יצירה הספר, יש לבחור את אחת האופציות הבאות",
        choose: "בחירה",
        pdf_image: "https://mebook.ai/wp-content/uploads/2023/09/%D7%9B%D7%A8%D7%99%D7%9B%D7%94-%D7%A2%D7%91%D7%A8%D7%99%D7%AA-e1694077951904-removebg-preview-1.png",
        hard_image: "https://mebook.ai/wp-content/uploads/2023/09/%D7%97%D7%A0%D7%95%D7%9B%D7%94-%D7%A2%D7%91%D7%A8%D7%99%D7%AA-1024x879.png",
        past_books: "ספרים שלא הושלמו",
        terms_modal_h: "הסכם זה מתאר את התנאים וההגבלות המסדירים רכישות שבוצעו דרך האתר והאפליקציות של MeBook. זה גם מספק פרטים לגבי מדיניות ההחזרות וההחזרים שלנו.",
        terms_modal: [
            {
                title: "מדיניות רכישות והחזרות",
                p: "ברוך הבא לאתר https://mebook.ai (\"האתר\") המנוהל על ידי מיבוק יזמות בע\"מ\n" +
                    "(\"החברה\"). תנאי שימוש אלו (להלן \"תנאי השימוש\") קובעים את התנאים, ההתניות\n" +
                    "והמגבלות אשר חלים על השימוש באתר, לרבות רכישת ספר אישי באמצעות האתר, ו/או\n" +
                    "קבלת מידע מכל מין וסוג שהוא הנוגע לשירותים אלו. כל שימוש באתר, ו\\או רכישת ספר\n" +
                    "אישי באמצעות האתר, ייחשב להסכמה מלאה ובלתי מסויגת מצדך כי קראת, הבנת, ואתה\n" +
                    "מסכים לתנאי השימוש, ולפיכך הינך מתבקש לקוראם בעיון רב בטרם ביצוע האמור לעיל,\n" +
                    "ולנהוג על פי הכתוב בהם. אנו שומרים על זכותנו להכניס שינויים בתנאי השימוש, על פי\n" +
                    "שיקול דעתנו הבלעדי, והמשך שימושך באתר או בשירותי החברה, לאחר פרסום תנאי\n" +
                    "שימוש מעודכנים, מהווה את הסכמתך לכל שינוי כאמור.\n" +
                    "אם אינך מסכים לאיזה מהתנאים האמורים בתנאי השימוש, נא הפסק לאלתר את השימוש באתר.\n" +
                    "הוראות תנאי השימוש יחולו על כל שימוש שתבצע באתר ו/או כל הזמנה של מוצר או שירות שתבצע\n" +
                    "באמצעות האתר, ויהוו את הבסיס המשפטי להתקשרות בינך לבין החברה.\n" +
                    "כל האמור בלשון זכר בהסכם זה נעשה מטעמי נוחות ומתייחס גם אל הנקבה.\n" +
                    "מהות האתר\n" +
                    "האתר מיועד לקבלת מידע והזמנה של ספרים מותאמים אישית, לילדים ומבוגרים, אשר מופקים\n" +
                    "באמצעות טכנולוגיות AI מתקדמת.\n" +
                    "השימוש באתר, מיועד למשתמשים מעל לגיל 18. במידה וגילך פחות מגיל 18, הנך מתחייב\n" +
                    "כי הוריך או האפוטרופוס החוקי שלך אישר את התקשרותך עם האתר ואת ביצוע ההזמנה\n" +
                    "באמצעות האתר. אין לבצע כל הזמנה באמצעות האתר במידה וגילך הינו פחות מגיל 13.\n" +
                    "כל האחריות בגין הנתונים שתמסור, חלה עליך ולפיכך הנך מתבקש לוודא כי כל הנתונים\n" +
                    "שמסרת הינם נכונים ומדויקים, וכי כל פרט שתמסור או תעביר הינו נכון ומדויק, והשימוש בהם\n" +
                    "יעשה בהתאם ובכפוף לתנאי הצהרת הפרטיות בלינק שלהלן: https://mebook.ai/privacy-policy-\n" +
                    "2/\n" +
                    "במסגרת השימוש באתר והזמנת ספר אישי, תהיה לך האפשרות להעלות תמונות שלך או של האדם אשר\n" +
                    "לו הנך מעוניין להקדיש את הספר האישי. האתר אינו מחייב אותך לעשות כן, והעלאת התמונות הינה\n" +
                    "לשיקול דעתך הבלעדי, ואינה תנאי לעריכת הספר האישי. הנך מצהיר ומאשר כי יש לך אישור מפורש\n" +
                    "מבעל התמונה ו\\או מבעל הזכויות בתמונה, להעלות את התמונה אשר הנך מעלה ו\\או לחלופין כי הנך\n" +
                    "האפוטרופוס החוקי או הטבעי של הילד\\אדם אשר הנך מעלה תמונות שלו לאתר, וכי ככל הנדרש על פי\n" +
                    "הדין, קיבלת את אישורו להעלות את התמונות האמורות. אין להעלות תמונות לאתר ללא הסכמת המצולם\n" +
                    "בהם ובמקרה של ילד, אזי הסכמת שני הורי הילד ו\\או האופטרופוס החוקי שלו.\n" +
                    "הספרים מותאמים אישית שבאתר נועדו לשמח אנשים, ואולם, יתכן כי בטעות יופק תוכן אשר עלול\n" +
                    "לפגוע ברגשותיהם של אנשים. החברה לא תהא אחראית בכל מקרה של פגישה ברגשות מכל סוג שהוא,\n" +
                    "ואולם, במידה ונתקלת בו עליך לדווח לחברה בהקדם בטרם עריכת הספר לשליחה, ואנו נשנה את התוכן\n" +
                    "בהתאם, ונשלח לך ספר חדש.\n" +
                    "הגבלות ואיסורים\n" +
                    "כתנאי לרכישת שירותים מהחברה הנך מתחייב כדלהלן: (א) כי הנך מעל גיל 18. (ב) כי\n" +
                    "במידה ותחליט לבצע את הרכישה באמצעות כרטיס אשראי אזי הנך הבעלים החוקי של\n" +
                    "\n" +
                    "כרטיס האשראי ושל חשבון הבנק שאליו משתייך כרטיס האשראי. (ג) כי יש בידיך את\n" +
                    "המסגרת הנדרשת בחשבון על מנת לבצע תשלומים בהתאם לשירותים שהנך מבקש לקבל.\n" +
                    "(ד) כי לא חל עליך כל איסור לבצע רכישה של שירותים או מוצרים מסוג אלו המוצעים לך על\n" +
                    "ידי החברה.\n" +
                    "הנך מתחייב כי לא תעשה כל שימוש באתר לצורך: (א) העלאת תכנים ו/או מידע שאין לך\n" +
                    "בהם בעלות או זכות שימוש בהם ו/או שמפרים את הדין ו/או צו שיפוטי כלשהו ו/או שעלולים\n" +
                    "להפר זכויות קנייניות של צד שלישי. (ב) הפרעה, שיבוש, הגבלה או מניעת שימוש באתר (ג)\n" +
                    "העלאת תכנים ומידע מסולפים, מטעים, שקריים ו\\או המזיקים לחברה, לבעליה, למנהליה\n" +
                    "ו/או לצד שלישי כלשהו. (ד) העלאת מידע פוגעני, מעליב, משמיץ מאיים חומר הפוגע או מפר\n" +
                    "זכויות קנייניות של אחרים, כל חומר פורנוגרפי או בעל אופי מיני בוטה או העלול לפגוע\n" +
                    "ברגשות הציבור ו/או כל חומר הנוגע לקטינים (שאינך האפוטרופוס שלהם) ומזהה אותם, ו/או\n" +
                    "כל חומר בלתי חוקי, או חומר המעודד ו/או תומך ו/או מסייע  בביצוע מעשה המהווה עבירה\n" +
                    "פלילית לפי דיני מדינת ישראל ו/או כל חומר המהווה לשון הרע על אדם או פוגע בפרטיות\n" +
                    "ו/או כל מידע אחר בעל אופי דומה. (ה) מטרה בלתי חוקית, או מטרה הסותרת את תנאי\n" +
                    "ההתקשרות. (ו) הטרדה או תלונת שווא או התחזות לאחר (ז) פגיעה בצד שלישי כלשהו\n" +
                    "לרבות בפרטיות או בצינעת הפרט. (ח) פגיעה ו/או הפרה של זכויות חוזיות, זכויות קנייניות,\n" +
                    "זכויות יוצרים, זכויות מוסריות, זכויות מעין קנייניות, חובות זהירות ואמון, סודות מסחריים,\n" +
                    "סימני מסחר, פטנטים.\n" +
                    "הנך מתחייב, שלא להשתמש באתר בקשר עם הפעולות הבאות: מסירת סיסמאות ו/או\n" +
                    "פרטים אישיים ו/או אינטימיים אודות אחרים ו/או הטרדת אחרים, התחזות לאחרים, עשיית\n" +
                    "פרסום ו/או קידום מכירות מסחריים, תעמולה פוליטית ו/או קידום מתמודדים ומפלגות\n" +
                    "בבחירות, שימוש בשפה בוטה ו/או פוגענית, איסוף פרטים על אחרים, העתקת פרטים ו/או\n" +
                    "מודעות מתוך האתר ופרסומם או הצגתם במקום אחר לצרכים מסחריים או באופן שעלול\n" +
                    "לפגוע בעסקי החברה, הצפת האתר , במידע, קידום תכנים מלאכותי, פרסום חומרים\n" +
                    "שקריים או מטעים, ו\\או פרסום קישורים.\n" +
                    "הנך מתחייב להימנע לחלוטין מכל ניסיון לאיסוף מידע על האתר לרבות באמצעים\n" +
                    "טכנולוגיים, הפעלה או סיוע לפעולת יישום מחשב או כל אמצעי אחר שמטרתם סריקת ו/או\n" +
                    "העתקת ו/או אחזור ו/או כריית מידע, להימנע מביצוע ו/או גרימת שינוי כלשהו באתר, ולא\n" +
                    "להתערב בקוד המקור של האתר.\n" +
                    "הנך מתחייב להימנע מביצוע פעולות ו/או מחדלים שיש בהם כדי להפריע לפעילות האתר\n" +
                    "ו/או הגולשים בו, לרבות בדרך של שיבוש ו/או הפרעה לפעילות מחשב כדוגמת \"וירוסים\",\n" +
                    "\"תולעים\" ויישומים מזיקים אחרים, חדירה לחומר מחשב, עריכת ו/או החדרת תוכנה באופן\n" +
                    "שעלול לגרום נזק או שיבוש למחשב ו/או חומר מחשב, מחיקת חומר מחשב, הסגת גבול\n" +
                    "וכיו\"ב.\n" +
                    "הנך מצהיר בזאת כי ידוע לך שהחברה רשאית לנקוט כנגד משתמש שיפר תנאים אלו בכל\n" +
                    "האמצעים העומדים לרשותו על פי דין, לרבות העברת פרטיו לצדדים שלישיים.\n" +
                    "למעט אם הותר באופן מפורש בתנאים אלו ללא הסכמה מוקדמת בכתב של החברה, אתה\n" +
                    "מסכים שלא: (i)  להשתמש, לשנות או לשלב את האתר , לתוך תוכנה אחרת, או ליצור\n" +
                    "יצירות נגזרות מכל חלק של האתר; ו – (ii) למכור, לתת רישיון (או רישיון משנה), להחכיר,\n" +
                    "להמחות, להעביר, למשכן או לשתף בזכויות שלך על פי תנאים אלו כל אדם אחר; (iii)\n" +
                    "להעתיק, להפיץ או לשעתק את האתר לטובת צדדים שלישיים; (iv) לגלות (disclose) את\n" +
                    "התוצאות של ביצועי האתר, או להשתמש בתוצאות אלה עבור פיתוח אתר מתחרה; ו/או (v)\n" +
                    "לשנות, לפרק, לעשות פעולות של הידור הפוך, הנדסה הפוכה, עדכון או שיפור של האתר ,\n" +
                    "או ניסיון לגלות את קוד המקור של האתר.\n" +
                    "\n" +
                    "בעלות וזכות הקניין\n" +
                    "ידוע לך כי כל התכנים המופיעים באתר ו/או בחומרים שימסרו או יגיעו אליך באמצעות האתר\n" +
                    "ו/או על ידי החברה, לרבות חומרים, עיצוב טקסט, תוכנה, מוזיקה, וידאו, גרפיקה חומרים\n" +
                    "המוכלים בפרסומות או בהודעות הנשלחות אליך או במידע מסחרי המוצע לך על ידי החברה,\n" +
                    "וכן תכני וקונספט הספר האישי שתפיק, יישארו קניינה הבלעדי של החברה בכל עת. כל\n" +
                    "זכויות הקניין הרוחני (לרבות, בין השאר, זכויות יוצרים, סודות מסחריים, סימני מסחר,\n" +
                    "פטנטים וכיוצא בזה) הקיימים ו/או מגולמים באתר ו/או הקשורים לספר האישי, ו/או מצורפים,\n" +
                    "קשורים, ו/או מתייחסים לאתר ו/או לספר האישי, הינם בבעלותה הבלעדית של החברה\n" +
                    "ויישארו בבעלותה  הבלעדית כאמור. אין בתנאים אלו בכדי להעביר אליך כל זכות באתר או\n" +
                    "בתכני הספר האישי, ו/או בחומרים שיועברו אליך אלא רק זכות מוגבלת להשתמש בהם\n" +
                    "בהתאם לתנאים אלו, ולהוראות הדין. שום דבר מהאמור בתנאים אלו אינו מהווה ויתור על\n" +
                    "זכויות הקניין הרוחני של החברה או צד שלישי כלשהו על פי כל דין.\n" +
                    "רישיון\n" +
                    "החברה מעניקה לך בזאת, ואתה מקבל, רישיון מוגבל, אישי, שאינו בלעדי, ללא זכות\n" +
                    "להמחאה, בלתי-מסחרי, שאין להעניק על בסיסו רישיונות משנה, ואשר ניתן לביטול באופן\n" +
                    "מלא, להשתמש באתר, כל זאת בהתאם לתנאים ולהוראות הנכללים בתנאים אלו. החברה\n" +
                    "שומרת לעצמה את כל הזכויות באתר ובחומרים שיסופקו לך לרבות הספר האישי אשר לא\n" +
                    "הוענקו במסגרת תנאים אלו במפורש.\n" +
                    "תחזוקה ותמיכה,\n" +
                    "לא חלה על החברה כל חובה על פי תנאים אלו לספק תמיכה, תחזוקה, שדרוגים, שינויים או\n" +
                    "גרסאות חדשות של האתר. עם זאת, החברה עשויה מפעם לפעם להוציא גרסאות שדרוג\n" +
                    "של האתר, ועשויה לשדרג באופן אלקטרוני ואוטומטי את האתר. אתה נותן את הסכמתך\n" +
                    "לשדרוג אוטומטי שכזה, ומסכים כי תנאים אלו יחולו על כל השדרוגים כאמור.\n" +
                    "ביטול עסקה\n" +
                    "ביטול עסקה שבוצעה באמצעות האתר או בטלפון יתאפשר בהתאם להוראות הדין ובפרט\n" +
                    "הוראות חוק הגנת הצרכן, התשמ\"א-1981 והתקנות שהותקנו מכוחו.\n" +
                    "עם הלחיצה על כפתור \"בחירה״ תועבר לעמוד התשלום בו תידרש להכניס את פרטי כרטיס\n" +
                    "האשראי שלך ולשלם עבור הזמנתך, וכן לאשר תנאי שימוש אלו ואת מדיניות הפרטיות.\n" +
                    "לאחר שתבצע את התשלום תישלח אליך חשבונית מס קבלה כדין. בכל עת תוכל להפסיק\n" +
                    "את הליך הרישום בטרם התשלום ולא תחויב.\n" +
                    "הואיל והזמנתך הינה מותאמת אישית, אזי לאחר ביצוע הזמנתך לא ניתן יהיה לבטלה,\n" +
                    "למעט אם טרם החל ביצועה, ולשיקול דעתה של החברה.\n" +
                    "הזיכוי יעשה בתוך 30 ימים ממועד מתן הודעת הביטול.\n" +
                    "הביטול לא יהא כרוך בעלות כלשהי, למעט דמי ביטול כפי המפורט להלן, וכל ההוצאות\n" +
                    "שהוציאה החברה, אשר ינוכו מסכום ההחזר שתקבל. בכל מקרה שבו שילמה החברה\n" +
                    "לספקי שירותים צדדים שלישיים, כגון בית דפוס אזי ינוכה הסכום ששולם מסכום ההחזר.\n" +
                    "בכל מקרה של ביטול הזמנתך לאחר שהחלה החברה לעבוד עליה, תחויב במחירה המלא.\n" +
                    "\n" +
                    "במידה וברצונך לבטל את העסקה, עליך להודיע לנו באמצעות מייל info@mebook.ai , או\n" +
                    "באמצעות הטלפון ⁦097666097⁩ ולפרט בהודעתך את שמך המלא וכתובת המייל שלך ,\n" +
                    "ואנו נודיעך אם ניתן לבטל את העסקה, וככל וניתן אזי נמחק את התכנים שהעברת לנו\n" +
                    "במסגרתה, בתוך זמן סביר.\n" +
                    "\n" +
                    "הגבלת אחריות\n" +
                    "כל מידע וחומרים שתחליט לשתף עם החברה במסגרת הפקת הספר האישי, ימסר על ידך\n" +
                    "מרצונך החופשי וידוע לך שאין לך כל חובה למסור אותו במסגרת או כתנאי לרכישת הספר\n" +
                    "האישי.\n" +
                    "לחברה לא תהא כל אחריות בגין תוכן או מהות המידע שתמסור או שתקבל במסגרת\n" +
                    "השימוש באתר, ו/או הספר האישי. ידוע לך כי בהפקת הספר מעורבים צדדים שלישיים\n" +
                    "לרבות בית דפוס וכיו\"ב, והנך מאשר כי לא יהיו לך כל טענות תביעות או דרישות מהחברה\n" +
                    "בכל הנוגע לעבודתם של צדדים שלישיים אלו.\n" +
                    "החברה אינה מתחייבת כי הספר האישי שיסופק לך ישיג מטרה או יעד מסוים כלשהו, ולא\n" +
                    "תהא לך כל טענה תביעה או דרישה כנגד החברה עקב אי השגת מטרות או יעדים כלשהם\n" +
                    "במסגרת הזמנת וקבלת הספר האישי ו/או במסגרת שירותיה של החברה. הספר האישי\n" +
                    "נכתב בטכנולוגית AI ולפיכך יתכנו תקלות באגים או סוגים שונים של ניסוחים ותוצרים,\n" +
                    "ולפיכך אין בו בכדי להוות ערובה או הבטחה כלשהי לשביעות רצון או לתוצאה מובטחת\n" +
                    "כלשהי.\n" +
                    "כל מידע שתקבל במסגרת האתר, אינו מהווה יעוץ או שידול או שכנוע כלשהו לביצוע פעולה\n" +
                    "כלשהי, וכל מסקנה או תובנה שתפיק או פעולה שתחליט לבצע, הינה על אחריותך בלבד.\n" +
                    "החברה, לרבות בעלי מניותיה ו/או מנהליה לא יחובו בכל אחריות מכל סוג שהוא לגבי\n" +
                    "החלטות אשר אתה תקבל ו/או תעשה בהסתמך על מידע, תכנים ושירותים המצויים באתר.\n" +
                    "אין באמור בתנאי השימוש משום מצג מצד החברה בדבר ההתאמה, האמינות, ומידת הדיוק\n" +
                    "של המידע, המוצרים והגרפיקה הנלווית הכלולים באתר, למטרה כלשהי.\n" +
                    "כל השימוש והמידע, הנכלל באתר, מוצע לך \"כמות שהוא \" (As is)בלא כל התחייבות, ובלא\n" +
                    "כל אחריות או התניה משתמעת בדבר התאמה למטרה מסוימת, קניין ואי-הפרה.\n" +
                    "ידוע לך והנך מאשר כי לשם כתיבת הספר האישי שתזמין במסגרת האתר משתמשת\n" +
                    "החברה בטכנולוגית AI, ולפיכך חלק מהתוצרים אינו נשלט באופן ישיר על ידי החברה, ואין\n" +
                    "לחברה חלק בכתיבתו, ולא יהיו לך טענות תביעות או דרישות מהחברה בקשר עם האמור.\n" +
                    "החברה אינה מבטיחה כי האתר, או תכנים כלשם יענו על דרישותיך או כי פעולת האתר\n" +
                    "תתבצע ללא הפרעות או ללא שגיאות. ככל שהדבר מותר על פי דין, החברה מחריגה בזאת\n" +
                    "באופן מפורש כל אחריות מפורשת, לרבות בין היתר, אחריות לזכות הקניין או לאי הפרה של\n" +
                    "זכות קניין, ומכל אחריות מכללא, לרבות בין השאר, אחריות מכללא לאיכות מסחרית\n" +
                    "ולהתאמה למטרה מסוימת. החברה אינה אחראית ואינה ערבה למידת הדיוק, השלמות,\n" +
                    "השימושים או האמינות של התוצאות אשר יושגו באמצעות שימוש באתר או בחומרים של\n" +
                    "החברה.\n" +
                    "בכפוף להוראות הדין החל, בשום מקרה לא תחול על החברה לרבות בעלי מניותיה ו/או\n" +
                    "מנהליה ו/או עובדיה ו/או ספקי שירותיה אחריות בגין נזק ישיר, עקיף, דמי נזיקין עונשיים,\n" +
                    "נזק מקרי, מיוחד או תוצאתי, או כל נזק אחר מכל סוג ומין בין לך ובין לכל צד שלישי שהוא,\n" +
                    "\n" +
                    "הנובעים או קשורים בכל דרך שהיא לספר האישי, לשימוש בשירותי האתר ו/או להזמנת\n" +
                    "שירותים באמצעות האתר ו/או לשימוש או לביצועי האתר, לעיכוב בשימוש או לאי-יכולת\n" +
                    "להשתמש באתר, לאספקה או לאי-אספקה של שירותים, או לכל מידע, תוכנה, מוצר, שירות\n" +
                    "וגרפיקה נלווית שיעשה בהם שימוש כדי לספק לך את השירותים ו\\או המוצרים שהזמנת,\n" +
                    "לדיוק הנתונים, להתאמתם למשתמש בטיבם, אופיים, והיקפם, לליקויים אפשריים, לתוכנו\n" +
                    "של המידע או לכל מעשה או מחדל אחר של החברה ועובדיה בין שהדבר מבוסס על חוזה,\n" +
                    "ובין על דיני נזיקין, אחריות מוחלטת או כל דבר אחר.\n" +
                    "בכפוף להוראות הדין החל, בשום מקרה לא תעלה אחריות החברה, בגין כל סיבה שהיא,\n" +
                    "אליך או אל כל מישהו מטעמך, על 100 ש\"ח\n" +
                    "אם אינך שבע-רצון מחלק כלשהו של האתר ו/או שירותי החברה, או מאחד מתנאי השימוש,\n" +
                    "הסעד היחיד והבלעדי העומד לרשותך הוא הפסקת השימוש באתר, ובשירותים המוצעים על\n" +
                    "ידי החברה.\n" +
                    "החברה שומרת על זכותה לסרב להעניק גישה לאתר או לחלקים ממנו לכל משתמש, לפי\n" +
                    "שיקול דעתה הבלעדי, וללא כל התראה מוקדמת.\n" +
                    "החברה שומרת על זכותה לגלות בכל עת מידע כפי שיידרש על מנת לקיים את הדרישות\n" +
                    "החלות במסגרת החוק, התקנות, הליכים משפטיים, או בהתאם לדרישת רשות מוסמכת,\n" +
                    "הכל על פי שיקול דעתה הבלעדי של החברה בכל מקרה.\n" +
                    "החברה רשאית בכל עת להפסיק את פעילות האתר כולו או חלקו, זמנית או בצורה קבועה.\n" +
                    "כללי\n" +
                    "תנאים אלו מוסדרים לפי חוקי מדינת ישראל. הנך מסכים בזה כי בכל מחלוקת הנובעת\n" +
                    "מהשימוש באתר או הקשורה בו, תחול סמכות השיפוט הבלעדית של בתי המשפט בעיר תל\n" +
                    "אביב יפו, ושם יהיה מקום השיפוט הייחודי. והיה אם ייקבע שחלק כלשהו מתנאים אלו אינו\n" +
                    "תקף או שאינו ניתן לאכיפה מכח הוראות החוקים הרלוונטיים, לרבות אך ללא הגבלה, סעיפי\n" +
                    "ההתנערות מאחריות והגבלת האחריות הנזכרים לעיל, אזי הסעיפים שנשלל תוקפם או\n" +
                    "שאינם ניתנים לאכיפה ייחשבו כאילו הוחלפו בסעיפים תקפים וניתנים לאכיפה שתוכנם תואם\n" +
                    "במידת הקירוב הגדולה ביותר את כוונת הסעיפים המקוריים, ואילו יתר סעיפי תנאים אלו\n" +
                    "יישארו בתוקפם. ויתר צד אחד, מפורשות או מכללא, על זכות הניתנת לו עפ\"י מסמך זה\n" +
                    "במקרה מסוים לא ילמדו ממנו גזירה שווה לכל מקרה אחר ולא יראו בכך ויתור על זכויות\n" +
                    "אותו צד עפ\"י תנאים אלו. גרסה מודפסת של תנאים אלו ושל כל הודעה שהועברה במתכונת\n" +
                    "אלקטרונית יהוו ראיה קבילה בהליכים משפטיים או מנהליים המבוססים או הקשורים בהם\n" +
                    "במידה שווה ובאותם תנאים החלים על שאר הרשומות והמסמכים העסקיים שהופקו ונשמרו\n" +
                    "במקור במתכונת מודפסת. כל זכות שאינה ניתנת בתנאי השימוש במפורש, דינה כזכות\n" +
                    "שמורה. הנך מתחייב לשפות את חברה בגין כל נזק הוצאה הפסד או תשלום שייגרמו לה ו/או לבעלי\n" +
                    "מניותיה ו/או למנהליה ו/או לאתר עקב הפרה על ידך של תנאי השימוש.\n" +
                    "לכל שאלה או הבהרה נשמח אם תיצור קשר לכתובת הדוא\"ל info@mebook.ai"
            },
            {
                title: "מדיניות פרטיות",
                p: "מטרתה של מדיניות הפרטיות הינה להבהיר למשתמשים ולמשתמשות באתר\n" +
                    "https://mebook.ai (\"האתר\") את מדיניות הפרטיות הנהוגה, לרבות תיאור אופן השימוש\n" +
                    "שיעשה על ידי מיבוק יזמות בע\"מ (\"החברה\"), במידע, איסופו ושימושיו. החברה רשאית\n" +
                    "לערוך או לשנות מעת לעת את מדיניות הפרטיות ושימושך באתר ו\\או בשירותים שהאתר\n" +
                    "מציע, לאחר עדכון ופרסום הצהרת פרטיות מעודכנת מהווה הסכמתך לשינוי כאמור.\n" +
                    "\n" +
                    "\n" +
                    "במקרה שבו הנך מתחת לגיל 18, הנך מתחייב/ת כי הצהרת פרטיות זו אושרה על ידי הוריך\n" +
                    "ו\\או האפוטרופוס החוקי שלך. אם זה אינו המצב, הנך נדרש/ת שלא להשתמש באתר ולא\n" +
                    "לשתף כל מידע אודותיך.\n" +
                    "האתר אוסף מידע לגבי כל המשתמשים והמשתמשות בו מתוך מטרה לספק את השירותים\n" +
                    "המבוקשים ו\\או לשפר את איכות וטיב השירותים שהאתר מעניק. קיימים מספר סוגי מידע\n" +
                    "אשר אנו אוגרים אודותיך:\n" +
                    "\n" +
                    "מידע כללי – מידע כללי על המשתמשים והמשתמשות באתר על בסיס מצטבר ואנונימי על\n" +
                    "מנת לשפר ולייעל את איכות וטיב השירותים הניתנים באמצעות האתר, באופן בו לא ניתן\n" +
                    "לשייך כל מידע מזהה ופרטים אישיים כלשהם למידע.\n" +
                    "מידע אישי – מידע הנאסף על ידי האתר מהמשתמשים והמשתמשות ונאגר במאגרי\n" +
                    "המידע של החברה שהינו בעל אופי אישי, כגון: שם מלא, טלפון, כתובת מייל וכיו\"ב. מידע\n" +
                    "אישי נאסף רק כאשר המשתמשים והמשתמשות באתר בוחרים/ות לספק מידע אישי כאמור\n" +
                    "מרצונם/ן החופשי לרבות בעת הכנסת פרטים אודותיהם/ן במסגרת הזמנת ספר אישי\n" +
                    "באמצעות האתר. מידע אישי כולל בנוסף מידע אשר נאסף על ידינו אודות משתמש/ת או\n" +
                    "האדם אשר עבורו מיועד להיכתב הספר האישי, במסגרת השירותים שתקבל/י מהחברה\n" +
                    "לרבות תמונות שתעלה/י לאתר על מנת שיכללו בספר האישי שהנך מזמין/ה וכיו\"ב.\n" +
                    "החברה נעזרת במידע האישי האמור על מנת לספק מענה לצרכים עבור המשתמשים\n" +
                    "והמשתמשות באתר ולרבות לשם הכנה של הספר האישי שתזמין/י.\n" +
                    "כל מידע אשר תמסור/י אודותיך במסגרת השימוש באתר, הינו וישאר בכל עת מידע אשר\n" +
                    "בבעלותך המלאה ואולם, עצם איסופו על ידנו ו\\או מסירתו על ידיך במסגרת האתר, או\n" +
                    "השירותים שתזמין/י באמצעותו, מהווה הסכמה מצידך לכך שהינך נותן/ת לחברה את הזכות\n" +
                    "להשתמש במידע זה, לצורך מתן השירותים שתזמין/י מהאתר, תפעולו של האתר, עיבודים\n" +
                    "סטטיסטיים, פילוחי מידע וכן לצורך קבלת דברי דואר בין בדואר רגיל ובין בדואר אלקטרוני\n" +
                    "ובין בכל דרך אחרת, לרבות הצעות בעלות תוכן שיווקי ופרסומי.\n" +
                    "אם אינך מעוניין/ת בדיוור כאמור, זכותך להודיע לנו כי אינך מעוניין/ת באמצעות המייל\n" +
                    "info@mebook.ai.\n" +
                    "החברה רשאית לשמור מידע שתאסוף אודותיך במאגריה, ולעשות בו שימוש, בכפוף לתנאי\n" +
                    "השימוש, מדיניות הפרטיות ועל פי הוראות כל דין.\n" +
                    "במסגרת השימוש באתר והזמנת ספר אישי, תהיה לך האפשרות להעלות תמונות שלך או\n" +
                    "של האדם אשר לו הנך מעוניין/ת להקדיש את הספר האישי. האתר אינו מחייב אותך לעשות\n" +
                    "כן, והעלאת התמונות הינה לשיקול דעתך הבלעדי, ואינה תנאי לעריכת הספר האישי. הנך\n" +
                    "מצהיר/ה ומאשר/ת כי יש לך אישור מפורש מבעל/ת התמונה ו\\או מבעל/ת הזכויות בתמונה,\n" +
                    "להעלות את התמונה אשר הנך מעלה ו\\או לחלופין כי הנך האפוטרופוס החוקי או הטבעי של\n" +
                    "הילד/ה או האדם אשר הנך מעלה תמונות שלו/ה לאתר, וכי ככל הנדרש על פי הדין, קיבלת\n" +
                    "את אישורו/ה להעלות את התמונות האמורות. אין להעלות תמונות לאתר ללא הסכמת\n" +
                    "המצולם/ת בהם ובמקרה של ילד/ה, אזי הסכמת שני הורי הילד/ה ו\\או האופטרופוס החוקי\n" +
                    "שלו/ה.\n" +
                    "\n" +
                    "\n" +
                    "החברה תהא רשאית לעשות שימוש בתמונות כאמור על מנת לבצע את הזמנת לספר אישי.\n" +
                    "בתוך 30 ימי עסקים לאחר מסירת הספר האישי, תמחק החברה את התמונות אשר העברת\n" +
                    "לחברה.\n" +
                    "לשם פעילותו השוטפת של האתר, לרבות לצורך איסופו של מידע, אימות פרטים, והתאמות\n" +
                    "האתר להעדפותיך, האתר עושה ו/או יעשה שימוש ב\"עוגיות\" (Cookies). עוגיות הן קבצי\n" +
                    "טקסט, אשר הדפדפן של המשתמש/ת יוצר לפי פקודה ממחשבי החברה. חלק מהעוגיות\n" +
                    "יפקעו כאשר תסגור/י את הדפדפן ואחרות נשמרות על גבי הכונן הקשיח במחשב שלך.\n" +
                    "העוגיות מכילות מידע מגוון כדוגמת הדפים שבהם ביקרת, משך הזמן ששהית בשימוש\n" +
                    "באתר, מהיכן הגעת אל האתר, מדורים ומידע שהמשתמש/ת מבקש/ת לראות בעת הכניסה\n" +
                    "לאתר, הרגלי הגלישה שלך, וכיו\"ב. המידע בעוגיות מוצפן, והחברה נוקטת צעדי זהירות כדי\n" +
                    "להבטיח שרק מחשביה או מי מטעמה יוכלו לקרוא ולהבין את המידע האגור בה. אם הנך\n" +
                    "מעוניין/ת להימנע מהכנסת \"עוגיה\" מעין זו מהאתר, עליך לשנות את ההגדרות במערכת\n" +
                    "ההפעלה ו/או בדפדפן בו הנך עושה שימוש. יתר על כן תוכל/י למחוק קבצים אלו שהושתלו\n" +
                    "במחשבך בכל עת.\n" +
                    "האתר מכיל קישורים לאתרים חיצוניים, וחלקו מסתמך על מידע המגיע מצדדים שלישיים.\n" +
                    "הנך מודע/ת לכך כי החברה אינה מתחייבת, כי המידע הינו אמין ומדויק ולא בדקה את\n" +
                    "אמינות המידע של אותם צדדים שלישיים. החברה לא תהיה אחראית לכל נזק, אובדן או\n" +
                    "חיוב מכל מין וסוג שהוא שייגרם לך כתוצאה מהסתמכות על מידע זה.\n" +
                    "החברה נוקטת באמצעי זהירות התואמים את הסטנדרטים הנהוגים בתחום על מנת לשמור,\n" +
                    "ככל האפשר, על סודיות מידע המתקבל על ידיך, ואולם, למרות האמור לעיל, קיים סיכון של\n" +
                    "חדירה למאגרי הנתונים של האתר ו/או החברה, וכן לתעבורת הנתונים בין מחשבך למחשבי\n" +
                    "האתר או החברה. לפיכך, הנך מאשר/ת כי החברה לא תהיה אחראית לכל אובדן נזק או\n" +
                    "תשלום שיגרם לך (אם יגרם) כתוצאה מחדירה למאגר הנתונים של החברה או האתר ו\\או\n" +
                    "העברת מידע אודותיך או מידע שנמסר על ידך לצד ג' כלשהו, וזאת ככל שנקטו בצעדים\n" +
                    "סבירים ומקובלים למניעת פעולות שכאלה.\n" +
                    "כל תשלום שנעשה באמצעות כרטיס אשראי או Paypal דרך האתר נעשה באופן מוצפן לפי\n" +
                    "תקן. החברה לא תהיה אחראית לכל נזק מכל סוג שהוא, עקיף או ישיר, שייגרם לך ו/או למי\n" +
                    "מטעמך במידה ומידע זה יאבד או אם יעשה בו שימוש לא מורשה.\n" +
                    "החברה לא תמכור או תעביר לאחרים מידע שיתקבל אודותיך, ללא הסכמתך המוקדמת,\n" +
                    "למעט במקרים הבאים: (א) חלה חובה על מסירת המידע בהתאם להוראות הדין או החלטה\n" +
                    "שיפוטית; (ב) ישנו סכסוך בינך ובין החברה בקשר עם המידע האמור; (ג) כאשר השימוש\n" +
                    "באתר נעשה בניגוד לאמור בתנאי השימוש, בהצהרת הפרטיות ו/או בניגוד להוראות כל דין;\n" +
                    "(ד) מועבר מידע שאינו מזהה אותך באופן אישי, שעיקרו הרגלי גלישה ומידע סטטיסטי (ה)\n" +
                    "המידע מועבר לספקי השירותים או עובדיה של החברה לצורך מתן השירותים שהזמנת;\n" +
                    "\n" +
                    "החברה לא תיחשב כמפרת התחייבות לפרטיות או פוגעת בפרטיות משתמש/ת בשל כל\n" +
                    "מידע, כהגדרתו בחוק המחשבים תשנ\"ה-1995, שיהיה בו כדי לזהות משתמש/ת או\n" +
                    "להתחקות אחריו/ה על ידי אחר, שנובע מהשימוש באמצעי תקשורת אלקטרוניים ככלל\n" +
                    "ותקשורת מחשבים בפרט.\n" +
                    "בכל מקרה שבו תרצה/י לעיין במידע אשר קיים אצלנו אודותיך ולעדכנו או לחלופין שנמחק\n" +
                    "מידע אישי אודותיך או את כל הפרטים שנאספו אודותיך, תהיה לך הזכות לפנות אלינו\n" +
                    "באמצעות מייל info@mebook.ai. בתוך 3 ימי עסקים ימחקו הפרטים שביקשת, למעט\n" +
                    "פרטים מינימליים אשר חובה עלינו לשמור על פי הדין ו\\או על פי שיקול דעתנו. דע/י כי\n" +
                    "מחיקה כאמור עלולה לפגוע בחלק מהשירותים שתקבל באמצעות האתר כעת או בעתיד.\n" +
                    "\n" +
                    "לכל שאלה או הבהרה נשמח אם תיצור קשר לכתובת המייל info@mebook.ai"
            },
        ],
        terms_modal_h2: "כיצד אנו משתמשים במידע:",
        terms_modal2: [
            { p: "1. ליצור ולספק את הספר המותאם אישית שאתה רוכש דרך השירותים שלנו." },
            { p: "2. לעבד עסקאות ולשלוח לך אישורי הזמנה ומשלוח." },
            { p: "3. להגיב לפניות שלך ולספק שירות לקוחות." },
            { p: "4. לשפר ולייעל את האתר והשירותים שלנו בהתבסס על דפוסי שימוש שלך באתר." },
            { p: "5. לשלוח הודעות ווטסאפ עבור התקדמות בתהליך יצירת הספר (המשך עריכה, תזכורות עבור רכישה של ספר שלא הושלם, תזכורות עבור ספר ששולם ולא נשלח להדפסה)" },
        ],
        preebook_title: "הסיפורים שלכם:",
        preebook_subTitle: "רק הספרים שתבחרו להשלים יכנסו להזמנה",
        otp: {
            title: "הזינו את מספר הטלפון שלכם",
            title_email: "הזינו את כתובת המייל שלכם",
            title_2: "נשלח SMS, הזינו את הקוד כאן",
            title_2_email: "נשלח מייל, הזינו את הקוד כאן",
            code_is_invalid: "הקוד אינו תקין",
            change_phone_number: "שינוי מספר טלפון",
            send_again: "שלחו שוב",
        }
    };
};

export default heb;
