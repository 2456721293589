import React from "react";
import { PhoneInput } from "react-international-phone";
import styled from "styled-components";
import { PhoneNumberUtil } from "google-libphonenumber";

const PhoneStyledWrapper = styled.div`
    .react-international-phone-input-container {
        direction: ltr;
    }

    .react-international-phone-input {
        width: 100%;
        height: 39px;
        font-size: 20px;
    }

    .react-international-phone-country-selector {
        //margin-inline-end: 15px;
        height: 39px;
    }

    .react-international-phone-country-selector-button {
        height: 39px;
    }

    &.error-phone-input {
        .react-international-phone-input, .react-international-phone-country-selector-button {
            border-color: #FF0000FF;
        }
    }

    button:disabled, button[disabled] {
        background: #fff;
    }
`;

const phoneUtil = PhoneNumberUtil.getInstance();

const PhoneInputComponent = ({ s_isPhoneError, isPhoneTouched, countries, config, isPhoneError, progress, s_isPhoneTouched, updateProgress }) => {
    return (
        <PhoneStyledWrapper
            className={ isPhoneError ? "error-phone-input" : "" }
        >
            <PhoneInput
                key={ progress?.phone }
                onFocus={ () => {
                    s_isPhoneTouched(true);
                } }
                defaultCountry={ config.countryCode || "il" }
                hideDropdown={ true }
                countries={ countries }
                showDisabledDialCodeAndPrefix={ true }
                autoFocus={ true }
                value={ progress?.phone }
                onChange={ (phone, meta) => {
                    try {
                        const isValid = phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
                        if (isPhoneTouched) {
                            if (isValid) {
                                s_isPhoneError(false);
                            } else {
                                s_isPhoneError(true);
                            }
                        }
                    } catch (e) {
                        if (isPhoneTouched) {
                            s_isPhoneError(true);
                        }
                    }
                    if (config.countryCode === "il") {
                        if (phone.startsWith("+9720")) {
                            phone = phone.replace("+9720", "+972");
                        }
                        if (phone.startsWith("+0")) {
                            phone = phone.replace("+0", "+972");
                        }

                        if (phone === "") {
                            phone = "+972";
                        }
                    }
                    if (config.countryCode === "us") {
                        if (phone === "") {
                            phone = "+1";
                        }
                    }

                    if (config.countryCode === "dk") {
                        if (phone === "") {
                            phone = "+45";
                        }
                    }

                    updateProgress("phone", phone);
                } }
            />
        </PhoneStyledWrapper>
    );
};

export default PhoneInputComponent;
