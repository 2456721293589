import { uploadFileV4 } from "../../ApiCalls";
import React, { useState, useEffect, useRef } from "react";
import info from "../images/info.svg";
import { CommonWrapperStyled } from "../commonStyles";
import { UploadIconSvg3 } from "../../svg";
import { motion } from "framer-motion";
import Modal from "../../common/Modal";
import Button from "./Button";
import { Tooltip } from "react-tooltip";
import { AlertRoleStyled } from "./Stage2";
import Loader from "./Loader";
import CONSTS from "../../consts";
import UploadImageError from "./uploadImage/UploadImageError";
import styled from "styled-components";
import UploadImagesDescription from "./uploadImage/UploadImagesDescription";
import HarlemShake from "./HarlemShake";
import { Cropper } from "react-mobile-cropper";
import { trackEvent, trackMixEvent } from "../../common/eventManager";
import { STAGES_STEPS } from "../../common/dataMaps";
import { getTokenCookie } from "../../common/cookie";

const UploaderWrapperStyled = styled.div`
    margin: 74px auto 66px;
    display: grid;
    grid-template-columns: 402px 563px;
    width: 967px;
    grid-gap: 27px;

    @media (max-width: 1000px) {
        display: block;
        width: 100%;
        grid-gap: 0;
        margin: 10px auto 16px;
    }

    .uploader {
        @media (max-width: 1000px) {
            width: 100%;
            margin: auto auto 15px;
        }

        h3 {
            font-size: 38px;
            font-weight: 400;
            margin: 0 0 14px 0;

            @media (max-width: 460px) {
                display: none;
            }
        }
    }
`;

const UploaderContentStyled = styled.div`
    .or {
        margin: 20px;
        position: relative;
        background: #fff;

        @media (max-width: 460px) {
            margin: 11px;
        }

        p {
            margin: 0 auto;
            background: #fff;
            width: fit-content;
            padding: 0 6px;
            z-index: 2;
            position: relative;
        }

        .hr {
            position: absolute;
            top: -9px;
            border-top: 1px solid #d3cece;
            left: 10%;
            right: 10%;
            @media (max-width: 460px) {
                top: -7px;
            }

        }
    }
`;

const CropperStyled = styled.div`
    width: 660px;
    height: 500px;
    margin: 10px auto;
    padding: 0 0 210px;

    @media (max-width: 661px) {
        width: 100%;
        height: 301px;
    }
`;

const CropperButtonStyled = styled.div`
    margin-top: 15px;

    .text-description-cropper {
        font-size: 16px;
    }
`;

function Stage1({
                    lang,
                    langData,
                    loading,
                    setLoading,
                    updateProgress,
                    getItemAnimation,
                    getItemAnimationAnlim,
                    s_currentStage,
                    imageBlob,
                    setImageBlob
                }) {

    const imageInputRef = useRef(null);
    const fileInputRef = useRef(null);
    const dnaRef = useRef(null);
    const exampleModalRef = useRef(null);
    const cropperRef = useRef(null);


    const [error, setError] = useState("");
    const [errorCode, setErrorCode] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [openSelection, setOpened] = useState(false);
    const [isDragOver, setIsDragOver] = useState(false);
    const [onImageFocus, s_onImageFocus] = useState(0);

    const [ariaAlert, s_ariaAlert] = useState("");

    const [showStage, s_showStage] = useState("REGULAR");

    const [fileDataStage, s_fileDataStage] = useState(null);

    const [, s_blobBuf] = useState(null);

    const images = CONSTS.preview_images;

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 1500); // Change image every 1.5 seconds

        return () => clearInterval(interval);
    }, [images.length]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (onImageFocus) {
                switch (event.key) {
                    case " ": // space
                    case "Enter": // space
                        imageInputRef.current.click();
                        break;
                    default:
                        break;
                }
            }
        };

        const divElement = imageInputRef.current;

        if (divElement) {
            divElement.addEventListener("keydown", handleKeyDown);
        }

        return () => {
            if (divElement) {
                divElement.removeEventListener("keydown", handleKeyDown);
            }
        };
    }, [onImageFocus]);

    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragOver(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragOver(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = "copy";
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragOver(false);

        const files = e.dataTransfer.files;
        handleFiles(files);
    };

    const handleFileSelect = (e) => {
        // const files = e.target.files;
        // handleFiles(files);
    };

    const handleFiles = async (files) => {
        const file = files[0];
        if (file) {
            await handleCropStage(file);
        }
    };


    const changeUpload = async (event) => {
        s_ariaAlert(langData.arias.image_load);
        setError("");
        if (!event.target.files[0]) {
            return false;
        }
        const file = event.target.files[0];
        if (file) {
            // await resizeFile(file);
            await handleCropStage(file);
        }
    };

    const handleCropStage = async (file) => {
        const blob = new Blob([file], { type: file.type });
        s_fileDataStage(URL.createObjectURL(blob));
        s_blobBuf(blob.slice());
        s_showStage("CROP");
    };

    const resizeFile = async (file) => {
        setLoading("imageUploading");
        setTimeout(() => {
            if (dnaRef.current) {
                dnaRef.current.focus();
            }
        }, 0);

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;
            img.onload = () => {
                const maxWidth = 1000;
                const maxHeight = 1000;

                let newWidth = img.width;
                let newHeight = img.height;

                // Resize if width or height is greater than 800px
                if (img.width > maxWidth || img.height > maxHeight) {
                    const aspectRatio = img.width / img.height;

                    if (aspectRatio > 1) {
                        newWidth = maxWidth;
                        newHeight = maxWidth / aspectRatio;
                    } else {
                        newWidth = maxHeight * aspectRatio;
                        newHeight = maxHeight;
                    }
                }

                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                canvas.width = newWidth;
                canvas.height = newHeight;

                ctx.drawImage(img, 0, 0, newWidth, newHeight);

                canvas.toBlob(blog => {
                    // s_fileDataStage(URL.createObjectURL(blog));
                    // s_blobBuf(blog.slice());
                    // s_showStage("CROP");
                    uploadImage(blog);
                }, file.type);
            };
        };
    };


    const uploadImage = async (file, isBlob) => {
        const formData = new FormData();

        let blob = file;
        if (!isBlob) {
            blob = new Blob([file], { type: file.type });
        }


        formData.append("file", blob);

        uploadFileV4(formData).then(data => {
            console.log("data", data);
            if (data.success) {
                updateProgress("eyeglasses", data?.data?.eyeglasses);
                updateProgress("boundingBox", data?.data?.boundingBox, true);
                updateProgress("faceDataId", data?.data?.faceDataId);
                const reader = new FileReader();

                reader.onloadend = function() {
                    const base64data = reader.result;
                    setImageBlob(base64data);
                    localStorage.setItem("imageBlob", base64data);
                    setLoading(false);
                    s_ariaAlert("");
                    setTimeout(() => {
                        if (imageInputRef?.current) {
                            imageInputRef.current.focus();
                        }
                    }, 0);
                };

                reader.readAsDataURL(blob);
            } else {
                const errorMsg = langData.image_errors[data.errorCode];

                const isValidError = CONSTS.valid_errors.indexOf(data.errorCode) !== -1;
                if (isValidError) {
                    setErrorCode(data.errorCode);
                    s_showStage("ERROR_IMAGE");
                    trackEvent("uploaded_image_not_good");
                }

                updateProgress("faceImageUrl", "");
                updateProgress("croppedFaceImageUrl", "");
                s_ariaAlert(errorMsg);
                setError(errorMsg);
                setLoading(false);
            }
            trackEvent("image_upload");
            trackMixEvent("image_upload", "success");
        }).catch(e => {
            updateProgress("faceImageUrl", "");
            updateProgress("croppedFaceImageUrl", "");
            s_ariaAlert(langData.error.many_faces);
            setError("Error while uploading image");
            setLoading(false);
            trackMixEvent("image_upload", "fail", "", e.message);
            console.log("error", e);
        });
    };

    const formatBytesToKB = (bytes, decimals = 2) => {
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizeInKB = bytes / k;
        return parseFloat(sizeInKB.toFixed(dm));
    };

    const openSelectionFunc = () => {
        setOpened(!openSelection);
        document.body.classList.remove("modal-active");
    };

    const nextStage = (event) => {
        if (!imageBlob) {
            event.preventDefault();
            setError(langData.error.error_upload);
            return false;
        }
        const token = getTokenCookie();
        if (token) {
            s_currentStage(STAGES_STEPS.INFO);
        } else {
            s_currentStage(STAGES_STEPS.OTP);
        }
    };

    return (
        <CommonWrapperStyled>
            { ariaAlert && (
                <AlertRoleStyled role="alert">
                    { ariaAlert }
                </AlertRoleStyled>
            ) }
            <h1 className="headline stage2-title">{ langData.stage1.title }</h1>
            { showStage === "ERROR_IMAGE" && (
                <div className="uploader poilz">
                    <UploadImageError
                        errorCode={ errorCode }
                        langData={ langData }
                        onClick={ () => {
                            setError("");
                            setErrorCode(null);
                            s_showStage("REGULAR");
                            trackEvent("try_again_upload_image");
                        } }
                    />
                </div>
            ) }
            { showStage === "REGULAR" && (
                <>
                    <UploaderWrapperStyled>
                        <div
                            onDragEnter={ handleDragEnter }
                            onDragOver={ handleDragOver }
                            onDragLeave={ handleDragLeave }
                            onDrop={ handleDrop }
                            className="uploader">
                            <h3>{ langData.stage1.image_is_here }</h3>
                            <form className="upload">
                                <label>
                                    { loading === "imageUploading" && (
                                        <div
                                            aria-label={ langData.arias.image_load }
                                            ref={ dnaRef }
                                        >
                                            <Loader
                                                ariaLabel={ langData.arias.image_load }
                                            />
                                        </div>
                                    ) }

                                    { loading !== "imageUploading" && (
                                        <div
                                            onFocus={ () => {
                                                s_onImageFocus(1);
                                            } }
                                            onBlur={ () => {
                                                s_onImageFocus(0);
                                            } }
                                            ref={ imageInputRef }
                                            role="button"
                                            tabIndex="0"
                                            aria-label={ !imageBlob ? langData.arias.image_to_load : langData.arias.loaded_image }
                                            className={ `${ !imageBlob ? "uploadbox" : "imageUploaded" }` }
                                        >
                                            <motion.div { ...getItemAnimation(0.4) } className="image">
                                                { imageBlob && (
                                                    <img
                                                        aria-label={ langData.arias.loaded_image }
                                                        src={ imageBlob } alt={ langData.arias.loaded_image }/>
                                                ) }
                                                { !imageBlob && (
                                                    <UploaderContentStyled>
                                                        <motion.div { ...getItemAnimationAnlim(0.4) }>
                                                            <UploadIconSvg3/>
                                                        </motion.div>
                                                        <div className="explain">
                                                            <p>{ langData.stage1.image_desc_1 }</p>
                                                            <p>{ langData.stage1.image_desc_2 }</p>
                                                        </div>
                                                        <div className="or">
                                                            <p>{ langData.stage1.or }</p>
                                                            <div className="hr"/>
                                                        </div>
                                                        <Button
                                                            onClick={ (e) => {
                                                                e.preventDefault();
                                                                fileInputRef.current.click();
                                                            } }
                                                            className="upload-button"
                                                        >
                                                            { langData.stage1.browse_files }
                                                        </Button>
                                                    </UploaderContentStyled>
                                                ) }
                                            </motion.div>
                                        </div>
                                    ) }
                                    <input
                                        ref={ fileInputRef }
                                        type="file"
                                        accept="image/jpg, image/png, image/jpeg"
                                        onChange={ changeUpload }/>
                                </label>
                                <div className="error">{ error }</div>
                            </form>
                        </div>
                        <UploadImagesDescription
                            langData={ langData }
                        />
                    </UploaderWrapperStyled>

                    { openSelection && (
                        <Modal
                            clickRef={ exampleModalRef }
                            role="alertdialog"
                            isOpen={ true }
                            onClose={ openSelectionFunc }
                            lang={ lang }
                            backGroundClose={ true }
                            ariaTitle={ langData.arias.exampleDialogTitle }
                        >
                            <div className="popup_container">
                                <ul className="bullet_list">
                                    <li><img src={ info }
                                             alt="תמונה של פנים דוגמה מספר 1"/><span>{ langData.stage1.popup_bullets.bullet_one }</span>
                                    </li>
                                    <li><img src={ info }
                                             alt="תמונה של פנים דוגמה מספר 2"/><span>{ langData.stage1.popup_bullets.bullet_two }</span>
                                    </li>
                                    <li><img src={ info }
                                             alt="תמונה של פנים דוגמה מספר 3"/><span>{ langData.stage1.popup_bullets.bullet_three }</span>
                                    </li>
                                </ul>
                                <div className="image-slider">
                                    { images.map((image, index) => (
                                        <img
                                            key={ index }
                                            src={ image }
                                            alt={ `slide ${ index }` }
                                            className={ `slider-image ${ index === currentIndex ? "visible" : "" }` }
                                        />
                                    )) }
                                </div>
                            </div>
                            <Button
                                tab={ "0" }
                                className="understandBtn"
                                onClick={ () => {
                                    openSelectionFunc();
                                } }>
                                { langData.stage1.popup_exit }
                            </Button>
                        </Modal>
                    ) }

                    <HarlemShake noShake={ !imageBlob }>
                        <Tooltip id="upload-image-please" className="pizeda"/>
                        <Button
                            dataTooltipId={ !imageBlob ? "upload-image-please" : null }
                            dataTooltipContent={ !imageBlob ? langData.please_upload_image : null }
                            onClick={ nextStage }
                            className="next-button"
                            disabled={ !imageBlob }
                        >
                            { langData.general.next }
                        </Button>
                    </HarlemShake>
                </>
            ) }

            { showStage === "CROP" && (
                <CropperStyled>
                    <Cropper
                        stencilProps={ {
                            aspectRatio: {
                                minimum: 1,
                                maximum: 1
                            }
                        } }
                        sizeRestrictions={ { minWidth: 100, minHeight: 100, maxWidth: null, maxHeight: null } }
                        ref={ cropperRef }
                        src={ fileDataStage }
                        onReady={ (e) => {
                            console.log("re");
                        } }
                        onChange={ (e) => {
                            console.log("p");
                        } }
                        className={ "cropper" }
                    />
                    <CropperButtonStyled>
                        <p className="text-description-cropper">{ langData.stage1.cropper_text }</p>
                        <Button
                            onClick={ () => {
                                const canvas = cropperRef.current.getCanvas();

                                if (canvas) {
                                    canvas.toBlob((blob) => {
                                        if (blob) {
                                            // s_afterCropBuf(blob);
                                            resizeFile(blob);
                                        }
                                    });
                                }
                                s_showStage("REGULAR");
                            } }
                            className="next-button"
                        >
                            { langData.general.next }
                        </Button>
                    </CropperButtonStyled>
                </CropperStyled>
            ) }
        </CommonWrapperStyled>
    );

}

export default Stage1;
