import React, { useContext, useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import styled from "styled-components";
import { PhoneNumberUtil } from "google-libphonenumber";
import {
    defaultCountries,
    parseCountry,
} from "react-international-phone";
import "react-international-phone/style.css";

import { useLocation, useNavigate } from "react-router";
import { MainContext } from "../ContextState";
import { chooseLang } from "../common/chooseLang";
import { getConfig, sendOtp, verifyOtp } from "../ApiCalls";
import { getTokenCookie, setTokenCookie } from "../common/cookie";
import PhoneInputComponent from "../stages/components/otp/PhoneInput";
import EmailInputComponent from "../stages/components/otp/EmailInput";
import Loader from "../stages/components/Loader";
import Button from "../stages/components/Button";

const phoneUtil = PhoneNumberUtil.getInstance();

const ActionsStyled = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    text-align: center;
    width: 340px;
    margin: 10px auto;

    .change-phone {
        color: #5d35ff;
        cursor: pointer;
    }

    .send-again {
        color: #5d35ff;
        cursor: pointer;
    }

    .disabled {
        color: #ccc;
        cursor: not-allowed;
        pointer-events: none;
    }
`;

const FormWrapperStyled = styled.div`
    display: grid;
    width: 241px;
    margin: 13px auto 0;
    position: relative;

    & > div {
        margin-bottom: 17px;
    }

    &.st-lb-en, &.st-lb-da {
        label {
            span {
                right: inherit !important;
                left: 18px !important;;
            }
        }

        .gender-option-span {
            right: inherit !important;
            left: 18px !important;;
        }
    }

    &.otp {
        width: 100%;

        input {
            width: 3rem !important;
            height: 3rem;
            margin: 0 1rem;
            font-size: 2rem;
            border-radius: 4px;
            border: 1px solid rgba(0, 0, 0, .3);

            @media (max-width: 568px) {
                height: 2rem;
                margin: 0 0.4rem;
                width: 2rem !important;
            }
        }
    }

    .otp-error {
        color: #FF0000FF;
        margin-top: 10px;
    }
`;

const OtpPageStyled = styled.div`
    width: 400px;
    margin: 10px auto;

    text-align: center;

    .information {

    }

    .sub-text {
        font-size: 22px;
    }
`;

export const AlertRoleStyled = styled.div`
    margin: 0;
    padding: 0;
    font-size: 0;
    position: fixed;
`;


const DELIVERY_TYPES = {
    SMS: "sms",
    EMAIL: "email"
};

function OtpStage() {
    const labelRefs = [];

    const location = useLocation();
    const navigate = useNavigate();
    const queryParameters = new URLSearchParams(location.search);
    const lang = queryParameters.get("language") || "he";
    const returnUrl = queryParameters.get("returnUrl");

    const { configContext, clientIdContext } = useContext(MainContext);
    const [config, s_config] = configContext;
    const [clientId] = clientIdContext;

    const token = getTokenCookie();

    const [otp, setOtp] = useState("");

    const [otpSourceValue, s_otpSourceValue] = useState("");

    const [isPhoneError, s_isPhoneError] = useState(false);
    const [isPhoneTouched, s_isPhoneTouched] = useState(false);

    const [emailErrorOpened, s_emailErrorOpened] = useState(false);

    const [otpMode, s_otpMode] = useState(false);
    const [loaderStep, s_loaderStep] = useState("");
    const [verificationError, s_verificationError] = useState(false);

    const [alertContent, s_alertContent] = useState("");

    const [timer, setTimer] = useState(null);
    const [sendAgainIsDisabled, s_sendAgainIsDisabled] = useState(false);

    const langData = chooseLang(lang);
    const otpDeliveryType = config?.otpDeliveryType;

    const goTo = () => {
        if (!returnUrl) {
            window.location.href = `/?clientId=${ clientId }&language=${ lang }`;
        } else {
            window.location.href = decodeURIComponent(returnUrl);
        }
    };

    useEffect(() => {
        if (token) {
            goTo();
        }
    }, [token]);

    useEffect(() => {
        if (clientId && !config) {
            getConfig(lang, clientId).then(data => {
                s_config(data);
            }).catch(e => {
                console.log("e", e);
            });
        }
    }, []);

    useEffect(() => {
        let interval;

        if (timer !== null) {
            interval = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer <= 1) {
                        clearInterval(interval);
                        s_sendAgainIsDisabled(false);
                        return null;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [timer]);

    useEffect(() => {
        if (otp.length === 4) {
            s_loaderStep("VERIFICATION");

            const dataToSend = {
                clientId: clientId,
                code: otp
            };

            if (otpDeliveryType === DELIVERY_TYPES.SMS) {
                dataToSend.phone = otpSourceValue;
            }
            if (otpDeliveryType === DELIVERY_TYPES.EMAIL) {
                dataToSend.email = otpSourceValue;
            }

            verifyOtp(dataToSend).then((res) => {
                if (res) {
                    s_loaderStep("");
                    setTokenCookie(res.token);
                    goTo();
                } else {
                    s_loaderStep("");
                    s_verificationError(true);
                }
            }).catch(() => {
                s_loaderStep("");
                s_verificationError(true);
            });
        }
    }, [otp]);

    const isPhoneValid = (phone) => {
        try {
            return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
        } catch (e) {
            return false;
        }
    };

    let isButtonDisabled = false;

    if (otpDeliveryType === DELIVERY_TYPES.SMS) {
        if (!otpSourceValue) {
            isButtonDisabled = true;
        }
        isButtonDisabled = isPhoneValid(otpSourceValue) === false;
    }

    if (otpDeliveryType === DELIVERY_TYPES.EMAIL) {
        if (!otpSourceValue) {
            isButtonDisabled = true;
        }
        if (emailErrorOpened) {
            isButtonDisabled = true;
        }
    }

    const countries = defaultCountries.filter((country) => {
        const { iso2 } = parseCountry(country);
        // return ['il', 'us'].includes(iso2);
        return [config?.countryCode].includes(iso2);
    });

    const classes = `st-lb-${ lang }`;

    labelRefs[0] = React.createRef();
    labelRefs[1] = React.createRef();

    let title = langData.otp?.title;

    const nextStage = async (event) => {
        if (isButtonDisabled) {
            event.preventDefault();
            if (otpDeliveryType === DELIVERY_TYPES.SMS && !otpSourceValue) {
                s_isPhoneError(true);
            }
            if (otpDeliveryType === DELIVERY_TYPES.SMS) {
                try {
                    const isValid = phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(otpSourceValue));
                    if (isValid) {
                        s_isPhoneError(false);
                    } else {
                        s_isPhoneError(true);
                    }
                } catch (e) {
                    s_isPhoneError(true);
                }
            }
            return false;
        }

        const dataToSend = {
            clientId: clientId,
            phone: otpSourceValue,
            email: otpSourceValue,
        };

        await sendOtp(dataToSend);
        s_otpMode(true);
        setTimer(60);
        s_sendAgainIsDisabled(true);
    };

    if (otpDeliveryType === DELIVERY_TYPES.EMAIL) {
        title = langData.otp?.title_email;
    }
    if (otpDeliveryType === DELIVERY_TYPES.SMS) {
        title = langData.otp?.title;
    }

    if (otpMode) {
        title = langData.otp?.title_2;

        if (otpDeliveryType === DELIVERY_TYPES.EMAIL) {
            title = langData.otp?.title_2_email;
        }
    }

    return (
        <OtpPageStyled className="otp">
            { alertContent && (
                <AlertRoleStyled role="alert">
                    { alertContent }
                </AlertRoleStyled>
            ) }
            <div className="information">
                <div className="sub-text">{ title }</div>
            </div>
            <div className="horizontal-line-stage2"/>

            { !otpMode && (
                <FormWrapperStyled className={ classes }>
                    { otpDeliveryType === DELIVERY_TYPES.SMS && (
                        <PhoneInputComponent
                            isPhoneError={ isPhoneError }
                            s_isPhoneTouched={ s_isPhoneTouched }
                            config={ config }
                            countries={ countries }
                            updateProgress={ (e, val) => {
                                s_otpSourceValue(val);
                            } }
                            progress={ { phone: otpSourceValue } }
                            isPhoneTouched={ isPhoneTouched }
                            s_isPhoneError={ s_isPhoneError }
                        />
                    ) }
                    { otpDeliveryType === DELIVERY_TYPES.EMAIL && (
                        <EmailInputComponent
                            emailErrorOpened={ emailErrorOpened }
                            langData={ langData }
                            s_alertContent={ s_alertContent }
                            updateProgress={ (e, val) => {
                                s_otpSourceValue(val);
                            } }
                            s_emailErrorOpened={ s_emailErrorOpened }
                        />
                    ) }

                    <div
                        style={ { width: "100%" } }
                        className="horizontal-line-stage2"/>
                </FormWrapperStyled>
            ) }
            { otpMode && (
                <FormWrapperStyled className={ `${ classes } otp` }>
                    <OtpInput
                        shouldAutoFocus={ true }
                        containerStyle={ {
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                            margin: "0 auto",
                            direction: "ltr"
                        } }
                        value={ otp }
                        onChange={ setOtp }
                        numInputs={ 4 }
                        inputType="number"
                        renderSeparator={ <span>-</span> }
                        renderInput={ (props) => <input { ...props } /> }
                    />

                    { loaderStep === "" && (
                        <ActionsStyled>
                            <div
                                onClick={ () => {
                                    s_otpMode(false);
                                    setOtp("");
                                } }
                                className="change-phone"
                            >
                                { langData.otp?.change_phone_number }
                            </div>
                            <div
                                onClick={ () => {
                                    const dataToSend = {
                                        clientId: clientId,
                                        phone: otpSourceValue,
                                        email: otpSourceValue,
                                    };

                                    sendOtp(dataToSend);
                                    setTimer(60);
                                    s_sendAgainIsDisabled(true);
                                } }
                                className={ `send-again ${ sendAgainIsDisabled ? "disabled" : "" }` }>
                                { langData.otp?.send_again } { sendAgainIsDisabled ? `(${ timer })` : "" }
                            </div>
                        </ActionsStyled>
                    ) }
                    { loaderStep === "VERIFICATION" && (
                        <Loader
                            height={ 50 }
                            width={ 50 }
                        />
                    ) }

                    { verificationError && (
                        <div className="otp-error">
                            { langData.otp?.code_is_invalid }
                        </div>
                    ) }
                </FormWrapperStyled>
            ) }

            { !otpMode && (
                <Button
                    disabled={ isButtonDisabled }
                    style={ { margin: "auto" } }
                    onClick={ nextStage }
                    className={ isButtonDisabled ? "next-button disabled" : "next-button" }
                >
                    { langData?.general?.next }
                </Button>
            ) }
        </OtpPageStyled>
    );

}

export default OtpStage;
