import React, {useContext, useEffect} from 'react';
import {MainContext} from "../ContextState";
import {useLocation} from "react-router-dom";
import {getMobileQuery} from "./utils";

const Resizer = ({children}) => {
    const {parentDataContext, dimensionsDataContext} = useContext(MainContext);
    const [parentData, s_parentData] = parentDataContext;
    const [, s_dimensionsData] = dimensionsDataContext;

    const location = useLocation();

    const queryParameters = new URLSearchParams(location.search);
    const isFullPage = queryParameters.get("isFullPage") || false;

    const innerWidth = getMobileQuery();
    const isMobile = innerWidth < 550;

    useEffect(() => {
        // Function to send the dimensions of the "root" div to the parent document
        const sendDimensions = () => {
            const rootDiv = document.getElementById('root');

            if (rootDiv) {

                let height = rootDiv.clientHeight + 50;
                if (isFullPage && isMobile) {
                    if (height < window.innerHeight) {
                        height = window.innerHeight;
                    }
                }
                const dimensions = {
                    type: 'DIMENSIONS',
                    width: rootDiv.clientWidth,
                    height: height
                };
                s_dimensionsData(dimensions);
                // Send dimensions to parent document
                window.parent.postMessage(dimensions, '*');
            }
        };

        // Attach the function to the 'resize' event and run it initially
        window.addEventListener('resize', sendDimensions);

        // Use ResizeObserver to detect changes in the size of the "root" div
        const resizeObserver = new ResizeObserver(sendDimensions);
        const rootDiv = document.getElementById('root');

        if (rootDiv) {
            resizeObserver.observe(rootDiv);
        }

        // Cleanup the event listener and observer when the component unmounts
        return () => {
            window.removeEventListener('resize', sendDimensions);
            resizeObserver.disconnect();
        };
    }, [isFullPage, parentData]);

    useEffect(() => {
        window.addEventListener('message', (event) => {
            if (event.data.type !== undefined) {
                if (event.data.type === 'PARENT_DATA') {
                    s_parentData({
                        scrollPosition: event.data.scrollPosition,
                        screenHeight: event.data.screenHeight,
                        screenWidth: event.data.screenWidth,
                        distanceTopToIframe: event.data.distanceTopToIframe,
                        distanceIframeToBottom: event.data.distanceIframeToBottom,
                    });
                }
            }
        });

        return () => {
            window.removeEventListener('message');
        };
    }, []);


    return children;
};

export default Resizer;