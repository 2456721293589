import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ArrowLeftButtonSvg, ArrowRightButtonSvg } from "../../svg";
import PlotModal from "./PlotModal";

const WrapperStyled = styled.div`
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    position: relative;
    min-height: 350px;

    @media (max-width: 1000px) {
        grid-template-columns: 1fr 6fr 1fr;
        min-height: 240px;
    }
`;

const ContainerStyled = styled.div`
    display: grid;
    grid-gap: 29px;
`;

const WrapperContainerStyled = styled.div`
    display: grid;
    position: relative;

    transition: left 0.5s cubic-bezier(0.37, 0, 0.63, 1) 0s;
`;

const WrapperOfWrapperContainerStyled = styled.div`
    overflow: hidden;

    &:focus {
        outline: 1px solid transparent;
    }
`;

const ItemStyled = styled.div`
    //display: grid;
`;

const ArrowStyled = styled.div`

`;

const DotsWrapperStyled = styled.div`
    position: absolute;
    bottom: -20px;
    display: grid;
    width: 100%;
    justify-content: center;
    grid-gap: 5px;

    @media (max-width: 1250px) {
        bottom: -1.7vw;
    }

    @media (max-width: 1000px) {
        bottom: -27px;
    }
`;

const DotsItemStyled = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    cursor: pointer;

    background: #FFA927;

    &:hover {
        background: #FFC43D;
    }

    &.active {
        background: #FFD542;
    }
`;


const ArrowItemStyled = styled.div`
    border-radius: 10px;
    background: #FFA927;

    display: inline-flex;
    padding: 10px;
    align-items: flex-start;
    gap: 10px;

    cursor: pointer;

    margin-top: 150px;

    &:hover {
        background: #FFC43D;
    }
`;

const Slider = (props) => {
    const {
        items,
        lang,
        langData,
        HtmlItem,
        maxInRow,
        maxRows,
        selectedBookTMP,
        selectedItem,
        getItemAnimation,
        forceIndexUpdate,
        progress
    } = props;

    const labelRefs = [];
    const sliderRef = useRef(null);
    const wrapperSliderRef = useRef(null);
    const [startX, setStartX] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [difference, s_difference] = useState(0);
    const [index, s_index] = useState(0);
    const [openSelection, setOpened] = useState(false);
    const [closeModalForce, s_closeModalForce] = useState(false);
    const [selectedPlotItem, s_selectedPlotItem] = useState(null);
    const [onFocusWrapper, s_onFocusWrapper] = useState(0);


    useEffect(() => {
        s_index(0);
    }, [forceIndexUpdate]);

    const handleTouchStart = (e) => {
        setStartX(e.touches[0].clientX);
        setIsDragging(true);
    };

    const handleTouchMove = (e) => {
        if (!isDragging) return;

        const currentX = e.touches[0].clientX;
        s_difference(startX - currentX);
        setStartX(currentX);
    };

    useEffect(() => {
        if (!isDragging) {
            if (difference < 0) { // swipe left
                if (isNextShow) {
                    nextPrev();
                }
            } else if (difference > 0) { // swipe right
                if (isPrevShow) {
                    nextPrev(true);
                }
            }
            s_difference(0);
        }
    }, [difference, isDragging]);

    useEffect(() => {
        if (selectedItem) {
            const index = items.findIndex(pp => pp._id === selectedItem);
            const slideIndex = Math.floor(index / (maxInRow * maxRows));
            if (index !== -1) {
                s_index(slideIndex);
            }
        }
    }, [items, selectedItem]);

    const handleTouchEnd = () => {
        setIsDragging(false);
    };

    const itemsLengh = items.length;
    const slides = Math.ceil(itemsLengh / (maxInRow * maxRows));
    const chunkSize = maxInRow * maxRows;

    const itemsInItems = [];
    for (let i = 0; i < items.length; i += chunkSize) {
        itemsInItems.push(items.slice(i, i + chunkSize));
    }

    const isNextShow = index + 1 < slides;
    const isPrevShow = index > 0;

    const nextPrev = (isPrev) => {
        if (isPrev) {
            s_index(index - 1);
        } else {
            s_index(index + 1);
        }
    };

    const openSelectionFunc = () => {
        setOpened(!openSelection);
        s_closeModalForce(false);
    };

    const dots = Array.from({ length: slides }, (_, index) => index);

    let left = `${ -100 * index }%`;
    if (lang === "he") {
        left = `${ 100 * index }%`;
    }

    return (
        <WrapperStyled
            ref={ wrapperSliderRef }
        >
            <ArrowStyled>
                { isPrevShow && (
                    <ArrowItemStyled
                        tabIndex="0"
                        aria-label={ langData.arias.next }
                        role="button"
                        onClick={ () => {
                            nextPrev(true);
                        } }
                        onKeyDown={ (e) => {
                            if (e.key === " " || e.key === "Enter") {
                                nextPrev(true);
                            }
                        } }
                    >
                        { lang === "he" && (
                            <ArrowRightButtonSvg/>
                        ) }
                        { lang !== "he" && (
                            <ArrowLeftButtonSvg/>
                        ) }
                    </ArrowItemStyled>
                ) }
            </ArrowStyled>

            <WrapperOfWrapperContainerStyled
                ref={ sliderRef }
                onTouchStart={ handleTouchStart }
                onTouchMove={ handleTouchMove }
                onTouchEnd={ handleTouchEnd }
                role="radiogroup"
                aria-label={ langData.arias.choose_template }
            >
                <WrapperContainerStyled
                    style={ {
                        width: `${ 100 * slides }%`,
                        left: left,
                        gridTemplateColumns: `repeat(${ slides }, 1fr)`
                    } }>
                    { itemsInItems.map((inItems, idx) => {

                        const selectedItemInThisList = inItems.find(pp => pp._id === selectedItem);
                        return (
                            <ContainerStyled
                                key={ idx + "3145" }
                                style={ {
                                    gridTemplateColumns: `repeat(${ maxInRow }, 1fr)`
                                } }>
                                { inItems.map((itm, idxx) => {
                                    const title = progress.gender === "female" ? itm.titleFemale : itm.titleMale;
                                    const isChecked = selectedItem === itm._id;
                                    let isFirstChecked = false;
                                    if (!selectedItem && idxx === 0 && idx === index) {
                                        isFirstChecked = true;
                                    }
                                    if (selectedItem && !selectedItemInThisList && idxx === 0 && idx === index) {
                                        isFirstChecked = true;
                                    }

                                    let tabIndexValue = "-1";
                                    if (isChecked || isFirstChecked) {
                                        tabIndexValue = "0";
                                    }
                                    if (!Array.isArray(labelRefs[idx])) {
                                        labelRefs[idx] = [];
                                    }
                                    labelRefs[idx][idxx] = React.createRef();

                                    const indexInItems = items.findIndex(pp => pp._id === itm._id);

                                    return (
                                        <ItemStyled
                                            ref={ labelRefs[idx][idxx] }
                                            role="radio"
                                            aria-setsize={ itemsLengh }
                                            aria-posinset={ indexInItems + 1 }
                                            tabIndex={ tabIndexValue }
                                            onKeyDown={ (e) => {
                                                if (e.key === " " || e.key === "Enter") {
                                                    selectedBookTMP(itm);
                                                }
                                                if (e.key === "ArrowLeft" || e.key === "ArrowDown") {
                                                    if (inItems[idxx + 1]) {
                                                        selectedBookTMP(inItems[idxx + 1]);
                                                        labelRefs[idx][idxx + 1].current.focus();
                                                    }
                                                }
                                                if (e.key === "ArrowRight" || e.key === "ArrowUp") {
                                                    if (inItems[idxx - 1]) {
                                                        selectedBookTMP(inItems[idxx - 1]);
                                                        labelRefs[idx][idxx - 1].current.focus();
                                                    }
                                                }
                                            } }
                                            aria-checked={ selectedItem === itm?._id }
                                            aria-label={ title }
                                            key={ idxx }>
                                            <HtmlItem
                                                onPlotShow={ (item) => {
                                                    s_selectedPlotItem(item);
                                                    openSelectionFunc();
                                                } }
                                                progress={ progress }
                                                langData={ langData }
                                                item={ itm }
                                                selectedBookTMP={ selectedBookTMP }
                                                selectedItem={ selectedItem }
                                                getItemAnimation={ getItemAnimation }
                                            />
                                        </ItemStyled>
                                    );
                                }) }
                            </ContainerStyled>
                        );
                    }) }
                </WrapperContainerStyled>
            </WrapperOfWrapperContainerStyled>

            <ArrowStyled>
                { isNextShow && (
                    <ArrowItemStyled
                        tabIndex="0"
                        aria-label={ langData.arias.prev }
                        onClick={ () => {
                            nextPrev();
                        } }
                        onKeyDown={ (e) => {
                            if (e.key === " " || e.key === "Enter") {
                                nextPrev();
                            }
                        } }
                    >
                        { lang === "he" && (
                            <ArrowLeftButtonSvg/>
                        ) }
                        { lang !== "he" && (
                            <ArrowRightButtonSvg/>
                        ) }
                    </ArrowItemStyled>
                ) }
            </ArrowStyled>
            <DotsWrapperStyled
                style={ {
                    gridTemplateColumns: `repeat(${ slides }, 10px)`
                } }
            >
                { dots.map((dot) => {
                    return (
                        <DotsItemStyled
                            onClick={ () => {
                                s_index(dot);
                            } }
                            key={ dot }
                            className={ index === dot ? "active" : "" }
                        />
                    );
                }) }
            </DotsWrapperStyled>

            { openSelection && (
                <PlotModal
                    progress={ progress }
                    selectedPlotItem={ selectedPlotItem }
                    openSelectionFunc={ openSelectionFunc }
                    lang={ lang }
                    closeModalForce={ closeModalForce }
                    ariaTitle={ langData.arias.templateInfoDialogTitle }
                />
            ) }
        </WrapperStyled>
    );
};

export default Slider;
