import React, {useState, useEffect, useContext, useRef} from "react";
import {getBookCategories, getBooksCoversAll} from "../../../ApiCalls";
import star from "../../images/star.svg";
import wand from "../../images/wand.svg";
import BookBlock from "./BookBlock";
import InfiniteProgressBar from "../ProgressBar";
import {motion} from "framer-motion";
import {CommonWrapperStyled, PumpLoaderStyled, VerticalLineStyled} from "../../commonStyles";
import styled from "styled-components";
import {MainContext} from "../../../ContextState";
import Slider from "../Slider";
import {getMobileQuery, transformImage} from "../../../common/utils";
import Button from "../Button";
import BookPreview from "../bookPreview/BookPreview";
import BookCoverPrev from "../BookCoverPrev";
import ChangingTextsProgress from "../ChangingTextsProgress";
import Modal from "../../../common/Modal";
import ClickOnMe from "../ClickOnMe";
import FansyCategories from "../FansyCategories";
import Loader from "../Loader";
import HarlemShake from "../HarlemShake";
import MobileSlider from "../MobileSlider";

const TitleStyled = styled.div`
    margin-top: 32px;

    @media (max-width: 480px) {
        margin-top: 12px;
    }

    span {
        color: #000;
        text-align: center;
        font-size: 31px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
`;

const PreviewWrapperStyled = styled.div`

    .coverPictures {
        margin: 0;
    }

    .preview-pump {
        height: 250px;
        width: 250px;
        border-radius: 15px;

        @media (max-width: 480px) {
            width: 100%;
            height: 150px;;
        }
    }

    .coverImage.fs {
        img {
            filter: blur(6px);
        }
    }

    .coverImage.upscale {
        img {
            filter: blur(4px);
        }
    }
`;

export const GettingPaidStyled2 = styled.div`
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    margin: 10px auto 20px;
    grid-gap: 18px;

    @media (max-width: 767px) {
        margin: 5px auto 20px;
        //display: block;
        width: 100%;
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 480px) {

    }

    .vertical-pay {
        @media (max-width: 767px) {
            display: none;
        }
    }

    .cont {
        position: relative;
        text-align: center;
        display: grid;
        justify-items: center;

        @media (max-width: 767px) {

        }

        h3 {
            font-size: 35px;
            margin: 5px 0;

            @media (max-width: 767px) {
                width: 100%;
                font-size: 25px;
            }
        }

        p {
            font-size: 19px;
            min-height: 70px;
            margin: 0 0 1px;

            @media (max-width: 767px) {
                min-height: auto;
                display: none;
            }
        }
    }


    .hard {
        margin-bottom: 50px;

        @media (max-width: 767px) {
            margin-bottom: 20px;
        }
    }

    .price {
        font-size: 26px;
        font-weight: 400;
        margin-bottom: 5px;

        @media (max-width: 767px) {
            margin: 10px 0 2px;
        }
    }

    .pay-book {
        &.pdf {
            @media (max-width: 767px) {
                .price {
                    margin-bottom: 2px;
                }
            }
        }
    }
`;

export const GettingPaidStyledAndPreview = styled.div`
    padding-top: 0;
    display: grid;
    //width: 100%;
    height: 365px;
    width: 727px;
    margin: auto;

    @media (max-width: 767px) {
        padding-top: 35px;
        width: 100%;
    }
`;

export const ModalPreviewWrapperStyled = styled.div`
    height: 590px;

    @media (max-width: 767px) {
        height: 90%;
    }
`;


export const ReadyImagesCoverStyled = styled.div`
    border: 4px solid transparent;
    transition: border 0.3s ease;
    border-radius: 9px;
    box-sizing: border-box;

    &.selected {
        border: 4px solid #FFC43D;
    }

    &:focus {
        outline: 1px solid transparent;
    }
`;

export const PoloirzsddStyled = styled.div`
    position: fixed;
    //position: absolute;
    width: 700px;
    height: 700px;
    left: ${props => (props.index + 1) * 4000}px;
`;


export const EditBookTextStyled = styled.div`
    padding: 0;
    margin: 4px 0 0 0;

    @media (max-width: 767px) {
        font-size: 12px;
    }
`;


function Stage4({
                    lang,
                    langData,
                    currentLang,
                    loading,
                    setLoading,
                    updateProgress,
                    selectedItem,
                    sendData,
                    isBookReady,
                    getItemAnimation,
                    progress,
                    bookData,
                    previewImages,
                    previewImagesStage,
                    isError,
                    chooseBookHandle,
                    setFocusToProgress,
                    sliderV2,
                    clientId
                }) {


    const innerWidth = getMobileQuery();
    const isMobile = innerWidth < 550;

    const elementToScrollToRef = useRef(null);
    const bookPreviewRef = useRef(null);
    const catsRef = useRef(null);
    const coversRef = useRef(null);
    const buttonRef = useRef(null);

    const [currentCat, setCurrentCat] = useState("all");
    const [error, setError] = useState("");
    const [forceIndexUpdate, s_forceIndexUpdate] = useState(0);

    const [openModal, s_openModal] = useState(false);
    const [closeModalForce, s_closeModalForce] = useState(false);

    const [scrolledToPreviewBook, s_scrolledToPreviewBook] = useState(false);

    const [width, s_width] = useState(0);

    const [catsFocusValue] = useState(0);

    const [onCoverFocus, s_onCoverFocus] = useState(0);

    const {categoriesContext, allTemplateContext} = useContext(MainContext);
    const [categories, s_categories] = categoriesContext;
    const [allTemplates, s_allTemplates] = allTemplateContext;
    const [isSendingData, s_isSendingData] = useState(false);

    useEffect(() => {
        if (!categories?.length) {
            getCategories();
        }
    }, []);

    useEffect(() => {
        if (isBookReady) {
            scrollToElement();
        }
    }, [isBookReady]);


    useEffect(() => {
        setTimeout(() => {
            if (bookPreviewRef?.current) {
                s_width(bookPreviewRef.current.offsetWidth);
            }
        }, 100);
    }, [bookPreviewRef?.current, progress.bookCover, openModal]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (catsFocusValue) {
                const currentCatIndex = categories.findIndex(bb => bb._id === currentCat);
                switch (event.key) {
                    case "ArrowLeft":
                        if (currentCatIndex === -1) {
                            setCurrentCat(categories[0]._id);
                        }
                        if (currentCatIndex > -1 && currentCatIndex < categories.length - 1) {
                            setCurrentCat(categories[currentCatIndex + 1]._id);
                        }
                        break;
                    case "ArrowRight":
                        if (currentCatIndex === 0) {
                            setCurrentCat("all");
                        }
                        if (currentCatIndex > 0 && currentCatIndex < categories.length) {
                            setCurrentCat(categories[currentCatIndex - 1]._id);
                        }
                        break;
                    case " ": // space
                    case "Enter": // space
                        break;
                    default:
                        break;
                }
            }
        };

        const divElement = catsRef.current;

        if (divElement) {
            divElement.addEventListener("keydown", handleKeyDown);
        }

        return () => {
            if (divElement) {
                divElement.removeEventListener("keydown", handleKeyDown);
            }
        };
    }, [catsFocusValue, currentCat]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (onCoverFocus) {
                switch (event.key) {
                    case " ": // space
                    case "Enter": // space
                        chooseBookCover(bookData.frontCoverData[`us_u${onCoverFocus}`]);
                        break;
                    default:
                        break;
                }
            }
        };

        const divElement = coversRef.current;

        if (divElement) {
            divElement.addEventListener("keydown", handleKeyDown);
        }

        return () => {
            if (divElement) {
                divElement.removeEventListener("keydown", handleKeyDown);
            }
        };
    }, [onCoverFocus]);

    useEffect(() => {
        if (buttonRef?.current) {
            const elementToScrollTo = buttonRef.current;
            const isFrame = window.self !== window.top;
            if (elementToScrollTo && !isFrame) {
                elementToScrollTo.scrollIntoView({behavior: "smooth"});
            }
        }
    }, [buttonRef, progress.templateId]);

    const sendBookData = (event) => {
        if (isSendingData) {
            return;
        }

        s_isSendingData(true);
        event.preventDefault();
        if (!progress.templateId) {
            setError(langData.error.error_template);
            return false;
        }
        sendData();
    };

    const openSelectionFunc = () => {
        s_openModal(!openModal);
        s_closeModalForce(false);
    };

    const getCurrentLangItem = (item, books = false) => {
        const langCheck = currentLang === "en";

        const key = (books ? (langCheck ? "en" : "he") : (langCheck ? 1 : 0));

        let val = item.nameKeys.find(pp => pp.lan === lang);

        if (!val) {
            val = item.nameKeys.find(pp => pp.lan === 'en');
        }

        return (books ? key : val.value);
    };

    const scrollToElement = () => {
        const elementToScrollTo = elementToScrollToRef.current;
        const isFrame = window.self !== window.top;
        if (elementToScrollTo && !isFrame) {
            elementToScrollToRef.current.focus();
        }
    };

    const scrollToPreviewBook = () => {
        const elementToScrollTo = bookPreviewRef.current;
        const isFrame = window.self !== window.top;
        if (elementToScrollTo && !isFrame) {
            elementToScrollTo.scrollIntoView({behavior: "smooth"});
        }
    };

    const updateProgressFunc = (key, value) => {
        updateProgress(key, value);
    };

    const findIndexByKey = (array, keyToFind) => {
        return array.findIndex(item => keyToFind in item);
    };

    const getCategories = async () => {
        try {
            setLoading("cats");
            const cats = await getBookCategories();

            if (allTemplates?.length === 0) {
                getBookCoversFunc(cats);
            }
            const index = findIndexByKey(cats, "isDefault");

            // if (isMobile) {
            //     setCurrentCat(cats[index]._id);
            // }
        } catch (error) {
            console.error("error fetching data of categories", error);
        }
    };

    const getBookCoversFunc = async (cats) => {
        try {
            setLoading("books");
            const booksCoversResult = await getBooksCoversAll(lang, clientId);
            // const templatesData = {...templates};
            // templatesData[id] = booksCoversResult;
            // s_templates(templatesData);

            const catsInUse = {};
            booksCoversResult.forEach(temp => {
                catsInUse[temp.category._id] = 1;
            });
            const tempCats = [];
            cats.forEach(pp => {
                if (Object.keys(catsInUse).indexOf(pp._id) !== -1) {
                    tempCats.push(pp);
                }
            });
            // if (!isMobile) {
            tempCats.unshift({
                    name: "הכל",
                    _id: "all",
                    nameKeys: [
                        {
                            lan: "he",
                            value: "הכל"
                        },
                        {
                            lan: "en",
                            value: "All"
                        },
                        {
                            lan: "zh",
                            value: "全部"
                        }
                    ]
                }
            );
            // }

            s_categories(tempCats);
            s_allTemplates(booksCoversResult);
            setTimeout(() => {
                setFocusToProgress();
            }, 0);
        } catch (error) {
            console.error("Error fetching data of Books templates:", error);
        } finally {
            setLoading(false);
        }
    };

    const chooseBookCover = (bookCoverImage) => {
        updateProgress("bookCover", bookCoverImage);
        if (!scrolledToPreviewBook) {
            scrollToPreviewBook();
            s_scrolledToPreviewBook(true);
        }
        s_openModal(true);
    };

    const changeCat = (catId) => {
        setCurrentCat(catId);
        s_forceIndexUpdate(forceIndexUpdate + 1);
    };

    const selectedTemplate = (item) => {
        updateProgressFunc("templateId", item._id);
    };

    let bookCovers = allTemplates;
    if (currentCat !== "all") {
        bookCovers = allTemplates.filter(p => p?.category?._id === currentCat) || [];
    }

    let maxInRow = 3;

    if (innerWidth < 1000) {
        maxInRow = 2;
    }

    if (innerWidth < 480) {
        maxInRow = 1;
    }

    const template = allTemplates?.find(temp => temp._id === progress.templateId);
    const templateTitle = progress?.gender === "female" ? template?.titleFemale : template?.titleMale;
    const coverDesign = template?.coverDesign;

    let catLen = categories?.length;
    if (isMobile) {
        catLen = catLen + 1;
    }

    return (
        <CommonWrapperStyled className="stage4-wrapper">
            {loading === "creatingBook" && (
                <>
                    <ChangingTextsProgress lang={langData}/>
                    <motion.div {...getItemAnimation(0.2, true)} className="loadingContainer">
                        <InfiniteProgressBar lang={langData}/>
                    </motion.div>
                    <PreviewWrapperStyled className="coverSection">
                        <div className="coverPictures">
                            {previewImagesStage === "init" && (
                                [1, 1, 1, 1].map((op, idx) => {
                                    return (<PumpLoaderStyled className="coverImage preview-pump" key={idx}/>);
                                })
                            )}
                            {(previewImagesStage === "fs" || previewImagesStage === "upscale") && (
                                previewImages.map((item, index) => {
                                    const transformedImage = transformImage(item, "w_500");
                                    return (
                                        <div key={index} className={`coverImage ${previewImagesStage}`}>
                                            <img src={transformedImage} alt={`preview image`}
                                                 className={`poki-image-${index}`}/>
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    </PreviewWrapperStyled>
                    <div className="wizard">
                        <p>{langData.do_not_refresh_the_page}</p>
                        <img src="https://print.mebook.ai/static/frame/mebook_logo.png?width=200" alt="wizard"/>
                    </div>
                </>
            )}

            {loading !== "creatingBook" && (
                <div className="stage4">

                    {!isBookReady && (
                        <>
                            <TitleStyled className="headline stage4">
                                <div className="staricon">
                                    <img src={star} alt="star-icon"/>
                                </div>
                                <span>{langData.stage4.title}</span>
                                <div className="staricon">
                                    <img src={star} alt="star-icon"/>
                                </div>
                            </TitleStyled>
                            <div
                                className="cat_list_d"
                                role="tablist">
                                {loading === "cats" && (
                                    <Loader
                                        height={80}
                                        width={80}
                                    />
                                )}
                                {loading !== "cats" && (
                                    <FansyCategories
                                        catLen={catLen}
                                        currentCat={currentCat}
                                        getCurrentLangItem={getCurrentLangItem}
                                        cats={categories}
                                        changeByIndexHandle={(idxxx => {
                                            changeCat(categories[idxxx]._id);
                                        })}
                                    />
                                )}
                            </div>
                            {sliderV2 && (
                                <>
                                    {!isMobile && (
                                        <Slider
                                            lang={lang}
                                            langData={langData}
                                            items={bookCovers}
                                            HtmlItem={BookBlock}
                                            maxInRow={maxInRow}
                                            maxRows={2}
                                            selectedBookTMP={selectedTemplate}
                                            selectedItem={selectedItem}
                                            getItemAnimation={getItemAnimation}
                                            forceIndexUpdate={forceIndexUpdate}
                                            progress={progress}
                                        />
                                    )}
                                    {isMobile && (
                                        <MobileSlider
                                            lang={lang}
                                            langData={langData}
                                            items={bookCovers}
                                            selectedItem={selectedItem}
                                            progress={progress}
                                            selectedTemplate={selectedTemplate}
                                            getItemAnimation={getItemAnimation}
                                        />
                                    )}
                                </>

                            )}
                            {!sliderV2 && (
                                <Slider
                                    lang={lang}
                                    langData={langData}
                                    items={bookCovers}
                                    HtmlItem={BookBlock}
                                    maxInRow={maxInRow}
                                    maxRows={2}
                                    selectedBookTMP={selectedTemplate}
                                    selectedItem={selectedItem}
                                    getItemAnimation={getItemAnimation}
                                    forceIndexUpdate={forceIndexUpdate}
                                    progress={progress}
                                />
                            )}

                            {sliderV2 && (
                                <div
                                    ref={buttonRef}
                                >
                                    {!progress.templateId && (
                                        <Button
                                            loading={isSendingData}
                                            onClick={sendBookData}
                                            className="personalise_btn next-button" disabled={!progress.templateId}>
                                            {langData.stage4.buttonText}
                                            <img className="wand-icon" src={wand} alt="wand"/>
                                        </Button>
                                    )}
                                    {progress.templateId && (
                                        <HarlemShake>
                                            <Button
                                                loading={isSendingData}
                                                onClick={sendBookData}
                                                className="personalise_btn next-button"
                                                disabled={!progress.templateId}>
                                                {langData.stage4.buttonText}
                                                <img className="wand-icon" src={wand} alt="wand"/>
                                            </Button>
                                        </HarlemShake>
                                    )}
                                </div>
                            )}

                            {!sliderV2 && progress.templateId && (
                                <div
                                    ref={buttonRef}
                                >
                                    <HarlemShake>
                                        <Button
                                            loading={isSendingData}
                                            onClick={sendBookData}
                                            className="personalise_btn next-button" disabled={!progress.templateId}>
                                            {langData.stage4.buttonText}
                                            <img className="wand-icon" src={wand} alt="wand"/>
                                        </Button>
                                    </HarlemShake>
                                </div>
                            )}
                            <div className="error">{error}</div>
                        </>
                    )}

                    {isBookReady && (
                        <>
                            {loading !== "postingData" && (
                                <div className="coverSection">
                                    <div
                                        tabIndex="0"
                                        ref={elementToScrollToRef}
                                        className="main_text">
                                        {langData.end_process.main_text}
                                    </div>
                                    {!isError && (
                                        <>
                                            <div className="coverPictures" ref={coversRef}>
                                                {[1, 2, 3, 4].map((index) => {
                                                    const imageUrl = bookData.frontCoverData[`us_u${index}`];
                                                    const transformedImage = transformImage(imageUrl, "w_1500");
                                                    const isSelected = progress.bookCover === imageUrl;

                                                    let classes = "coverImage bookIsReady";
                                                    if (isSelected) {
                                                        classes += " selected";
                                                    }

                                                    return (
                                                        <ReadyImagesCoverStyled
                                                            tabIndex="0"
                                                            role="button"
                                                            aria-label={`${langData.arias.cover_selection} ${index}`}
                                                            key={index}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                chooseBookCover(imageUrl);
                                                                return false;
                                                            }}
                                                            onFocus={() => {
                                                                s_onCoverFocus(index);
                                                            }}
                                                            onBlur={() => {
                                                                s_onCoverFocus(0);
                                                            }}
                                                            className={classes}>
                                                            <BookCoverPrev
                                                                isSelected={isSelected}
                                                                index={index}
                                                                imageUrl={transformedImage}
                                                                lang={lang}
                                                                coverDesign={coverDesign}
                                                                text={templateTitle}
                                                            />
                                                            <PoloirzsddStyled index={index}>
                                                                <BookCoverPrev
                                                                    isForCapture={true}
                                                                    isSelected={isSelected}
                                                                    index={index}
                                                                    imageUrl={transformedImage}
                                                                    lang={lang}
                                                                    coverDesign={coverDesign}
                                                                    text={templateTitle}
                                                                />
                                                            </PoloirzsddStyled>
                                                            {/*<img src={transformedImage} alt={`cover_${index}`}/>*/}
                                                        </ReadyImagesCoverStyled>
                                                    );
                                                })}
                                            </div>
                                            {openModal && (
                                                <Modal
                                                    role="alertdialog"
                                                    ariaTitle={langData.arias.payDialogTitle}
                                                    isOpen={true}
                                                    onClose={openSelectionFunc}
                                                    lang={lang}
                                                    closeModal={closeModalForce}
                                                >
                                                    <div className="popup_container">
                                                        <ModalPreviewWrapperStyled
                                                            ref={bookPreviewRef}
                                                        >
                                                            {progress.bookCover && (
                                                                <GettingPaidStyledAndPreview
                                                                    className={`lang-${lang}`}
                                                                >
                                                                    <ClickOnMe
                                                                        langData={langData}
                                                                    />
                                                                    <BookPreview
                                                                        width={width}
                                                                        bookCoverData={
                                                                            {
                                                                                img: progress.bookCover,
                                                                                text: templateTitle,
                                                                                coverDesign
                                                                            }
                                                                        }
                                                                        lang={lang}
                                                                        langData={langData}
                                                                        book={[...bookData?.bookPagesData?.filter(pp => pp.us_u1)]}
                                                                    />
                                                                    <EditBookTextStyled>
                                                                        {langData.continue_editing_book}
                                                                    </EditBookTextStyled>
                                                                    <GettingPaidStyled2 className={`lang-${lang}`}>
                                                                        <div className="pay-book hard">
                                                                            <div className="cont">
                                                                                <h3>{langData.end_process.choose_cover_hard}</h3>
                                                                                <p>{langData.end_process.p_description_hard}</p>
                                                                                <div className="price">
                                                                                    {langData.end_process.price_book}
                                                                                </div>
                                                                                <Button
                                                                                    ariaLabel={`${langData.end_process.choose_cover_hard} ${langData.end_process.price_book}`}
                                                                                    loading={loading === "makingCover_book"}
                                                                                    not_clickable={loading === "makingCover_pdf"}
                                                                                    onClick={() => chooseBookHandle("book")}
                                                                                    className="next-button choose-cover"
                                                                                >
                                                                                    {langData.choose}
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                        <VerticalLineStyled className="vertical-pay"/>
                                                                        <div className="pay-book pdf">
                                                                            <div className="cont">
                                                                                <h3>{langData.end_process.choose_cover_pdf}</h3>
                                                                                <p>{langData.end_process.p_description_pdf}</p>
                                                                                <div className="price">
                                                                                    {langData.end_process.price_pdf}
                                                                                </div>
                                                                                <Button
                                                                                    ariaLabel={`${langData.end_process.choose_cover_pdf} ${langData.end_process.price_pdf}`}
                                                                                    loading={loading === "makingCover_pdf"}
                                                                                    not_clickable={loading === "makingCover_book"}
                                                                                    onClick={() => chooseBookHandle("pdf")}
                                                                                    className="next-button choose-cover"
                                                                                >
                                                                                    {langData.choose}
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    </GettingPaidStyled2>
                                                                </GettingPaidStyledAndPreview>
                                                            )}
                                                        </ModalPreviewWrapperStyled>
                                                    </div>
                                                </Modal>
                                            )}
                                        </>
                                    )}
                                </div>
                            )}

                            {loading === "postingData" && (
                                <Loader
                                    height={80}
                                    width={80}
                                />
                            )}
                        </>
                    )}
                </div>
            )}
        </CommonWrapperStyled>
    );

}

export default Stage4;
