import {createContext, useState} from "react";
import { STAGES_STEPS } from "./common/dataMaps";

export const MainContext = createContext(null);

export const MainContextProvider = ({children}) => {
    const [lang, s_lang] = useState("he");
    const [currentStage, s_currentStage] = useState(STAGES_STEPS.MEDIA);
    const [categories, s_categories] = useState([]);
    const [templates, s_templates] = useState({});
    const [allTemplates, s_allTemplates] = useState([]);
    const [params, s_params] = useState({});
    const [config, s_config] = useState(null);
    const [parentData, s_parentData] = useState(null);
    const [dimensionsData, s_dimensionsData] = useState({});
    const [clientId, s_clientId] = useState(null);

    return (
        <MainContext.Provider
            value={{
                stageNumberContext: [currentStage, s_currentStage],
                categoriesContext: [categories, s_categories],
                templateContext: [templates, s_templates],
                allTemplateContext: [allTemplates, s_allTemplates],
                paramsContext: [params, s_params],
                configContext: [config, s_config],
                parentDataContext: [parentData, s_parentData],
                dimensionsDataContext: [dimensionsData, s_dimensionsData],
                clientIdContext: [clientId, s_clientId],
            }}
        >
            {children}
        </MainContext.Provider>
    );
};
