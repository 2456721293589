import React from "react";
import CustomInput from "../CustomInput";
import { Tooltip } from "react-tooltip";

const EmailInputComponent = ({s_alertContent, s_emailErrorOpened, updateProgress, progress, langData, emailErrorOpened }) => {
    return (
        <>
            <Tooltip id="warning-tooltip-email" className="pizeda" isOpen={ emailErrorOpened }/>
            <div
                data-tooltip-id="warning-tooltip-email"
                data-tooltip-content={ langData.stage2.fields.email_validation }
                data-tooltip-variant="error"
            >
                <CustomInput
                    ariaDescribedby={ "warning-tooltip-email" }
                    ariaRequired={ true }
                    errormessage={ emailErrorOpened ? langData.stage2.fields.email_validation : "" }
                    onBlur={ () => {
                        const isEmail = /\S+@\S+\.\S+/.test(progress.email);
                        if (!isEmail) {
                            s_emailErrorOpened(true);
                            s_alertContent(langData.stage2.fields.email_validation);
                            setTimeout(() => {
                                s_alertContent("");
                            }, 1000);
                        } else {
                            s_emailErrorOpened(false);
                            s_alertContent("");
                        }
                    } }
                    isInvalid={ emailErrorOpened }
                    type="email"
                    inputmode="email"
                    label={ langData.stage2.fields.email }
                    value={ progress.email }
                    onChange={ (event) => updateProgress("email", event.target.value) }
                />
            </div>
        </>
    );
};

export default EmailInputComponent;
