import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {getMobileQuery, transformImage} from "../../common/utils";
import BookCoverPrev from "./BookCoverPrev";
import Modal from "../../common/Modal";
import ClickOnMe from "./ClickOnMe";
import BookPreview from "./bookPreview/BookPreview";
import Button from "./Button";
import {VerticalLineStyled} from "../commonStyles";
import {
    EditBookTextStyled, GettingPaidStyled2,
    GettingPaidStyledAndPreview,
    ModalPreviewWrapperStyled,
    PoloirzsddStyled,
    ReadyImagesCoverStyled
} from "./Stage4/Stage4";
import { EDITOR_URL } from "../../consts";

const WrapperStyled = styled.div`
    .no_main_text {
        font-size: 27px;
        font-weight: 400;
        width: 50%;
        margin: 0 auto 10px auto;

        @media (max-width: 767px) {
            width: 98%;
        }
    }

    .no_desc {
        font-size: 21px;
        font-weight: 400;
        width: 50%;
        margin: auto;
        text-decoration: underline;

        @media (max-width: 767px) {
            width: 98%;
        }
    }
`;

const SeparatorStyled = styled.div`
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 60%;
    margin: 10px auto 10px;

    @media (max-width: 767px) {
        margin: 25px auto 0;
    }
`;


const NotComplitedBooks = (props) => {
    const coversRef = useRef(null);
    const bookPreviewRef = useRef(null);

    const {langData, lang, loading, currentLang, setLoading, books, chooseBookHandle, recentPrebooks} = props;

    const [selectedBook, s_selectedBook] = useState(null);
    const [openModal, s_openModal] = useState(false);
    const [onCoverFocus, s_onCoverFocus] = useState(0);
    const [closeModalForce, s_closeModalForce] = useState(false);
    const [width, s_width] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            if (bookPreviewRef?.current) {
                s_width(bookPreviewRef.current.offsetWidth);
            }
        }, 100);
    }, [bookPreviewRef?.current, openModal]);

    const openSelectionFunc = () => {
        s_openModal(!openModal);
        s_closeModalForce(false);
        s_selectedBook(null);
    };

    const redirect = async (item) => {
        if (item) {
            window.open(`${EDITOR_URL}?bookId=${item._id}&token=null&language=${lang}`, '_blank');
        }
    }

    return (
        <WrapperStyled>
            <div className="coverSection">
                <div
                    tabIndex="0"
                    className="no_main_text">
                    {langData.stage5.banned_title}
                </div>
                <p className="no_desc">
                    {langData.stage5.desc}
                </p>
                {recentPrebooks.map((book, idx) => {
                    if (!book?.frontCoverData?.images?.length) {
                        return null;
                    }
                    const {inputData} = book;

                    const templateTitle = inputData?.gender === 'female' ? book.template?.titleFemale : book.template?.titleMale;
                    const coverDesign = book.template.coverDesign;

                    return (
                        <div key={book._id + idx}>
                            <div className="coverPictures" ref={coversRef}>
                                {book.frontCoverData.images.map((item, index) => {
                                    const imageUrl = item;
                                    const transformedImage = transformImage(imageUrl, 'w_1500');
                                    const isSelected = imageUrl === selectedBook?.bookCover;

                                    let classes = 'coverImage bookIsReady';
                                    if (isSelected) {
                                        classes += ' selected';
                                    }

                                    return (
                                        <ReadyImagesCoverStyled
                                            tabIndex="0"
                                            role="button"
                                            aria-label={`${langData.arias.cover_selection} ${index}`}
                                            key={index}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                book.bookCover = transformedImage;
                                                s_selectedBook(book);
                                                redirect(book)
                                                return false;
                                            }}
                                            onFocus={() => {
                                                s_onCoverFocus(index);
                                            }}
                                            onBlur={() => {
                                                s_onCoverFocus(0);
                                            }}
                                            className={classes}>
                                            <BookCoverPrev
                                                isSelected={isSelected}
                                                index={index}
                                                imageUrl={transformedImage}
                                                lang={lang}
                                                coverDesign={coverDesign}
                                                text={templateTitle}
                                            />
                                            <PoloirzsddStyled index={index}>
                                                <BookCoverPrev
                                                    bookId={book._id}
                                                    isForCapture={true}
                                                    isSelected={isSelected}
                                                    index={index}
                                                    imageUrl={transformedImage}
                                                    lang={lang}
                                                    coverDesign={coverDesign}
                                                    text={templateTitle}
                                                />
                                            </PoloirzsddStyled>
                                        </ReadyImagesCoverStyled>
                                    );
                                })}
                            </div>
                            <SeparatorStyled/>
                        </div>
                    )
                })}
            </div>
            {openModal && (
                <Modal isOpen={true} onClose={openSelectionFunc} lang={lang}
                       closeModal={closeModalForce}>
                    <div className="popup_container">
                        <ModalPreviewWrapperStyled
                            ref={bookPreviewRef}
                        >
                            {selectedBook.bookCover && (
                                <GettingPaidStyledAndPreview
                                    className={`lang-${lang}`}
                                >
                                    <ClickOnMe
                                        langData={langData}
                                    />
                                    <BookPreview
                                        width={width}
                                        bookCoverData={
                                            {
                                                img: selectedBook.bookCover,
                                                text: selectedBook?.inputData?.gender === 'female' ? selectedBook.template?.titleFemale : selectedBook.template?.titleMale,
                                                coverDesign: selectedBook.template.coverDesign
                                            }
                                        }
                                        lang={lang}
                                        langData={langData}
                                        book={[...selectedBook?.bookPagesData?.filter(pp => pp.us_u1)]}
                                    />
                                    <EditBookTextStyled>
                                        {langData.continue_editing_book}
                                    </EditBookTextStyled>
                                    <GettingPaidStyled2 className={`lang-${lang}`}>
                                        <div className="pay-book hard">
                                            <div className="cont">
                                                <h3>{langData.end_process.choose_cover_hard}</h3>
                                                <p>{langData.end_process.p_description_hard}</p>
                                                <div className="price">
                                                    {langData.end_process.price_book}
                                                </div>
                                                <Button
                                                    ariaLabel={`${langData.end_process.choose_cover_hard} ${langData.end_process.price_book}`}
                                                    loading={loading === 'makingCover_book'}
                                                    not_clickable={loading === 'makingCover_pdf'}
                                                    onClick={() => chooseBookHandle("book", selectedBook)}
                                                    className="next-button choose-cover"
                                                >
                                                    {langData.choose}
                                                </Button>
                                            </div>
                                        </div>
                                        <VerticalLineStyled className="vertical-pay"/>
                                        <div className="pay-book pdf">
                                            <div className="cont">
                                                <h3>{langData.end_process.choose_cover_pdf}</h3>
                                                <p>{langData.end_process.p_description_pdf}</p>
                                                <div className="price">
                                                    {langData.end_process.price_pdf}
                                                </div>
                                                <Button
                                                    ariaLabel={`${langData.end_process.choose_cover_pdf} ${langData.end_process.price_pdf}`}
                                                    loading={loading === 'makingCover_pdf'}
                                                    not_clickable={loading === 'makingCover_book'}
                                                    onClick={() => chooseBookHandle("pdf", selectedBook)}
                                                    className="next-button choose-cover"
                                                >
                                                    {langData.choose}
                                                </Button>
                                            </div>
                                        </div>
                                    </GettingPaidStyled2>
                                </GettingPaidStyledAndPreview>
                            )}
                        </ModalPreviewWrapperStyled>
                    </div>
                </Modal>
            )}
        </WrapperStyled>
    );
};

export default NotComplitedBooks;
