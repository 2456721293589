const ch = () => {
    return {
        "image_errors": {
            6000: "无法分析人脸图像",
            6001: "图像中不包含人脸",
            6002: "图像中包含多个人脸",
            6003: "人脸被遮挡",
            6004: "闭眼",
            6005: "戴墨镜",
            6006: "人脸倾斜",
            6007: "人脸太小"
        },
        "image_error_labels": {
            6000: "无法分析人脸图像",
            6001: "无人脸",
            6002: "多个人脸",
            6003: "人脸被遮挡",
            6004: "闭眼",
            6005: "戴墨镜",
            6006: "人脸倾斜",
            6007: "人脸太小",
            "normal": "清晰的照片"
        },
        "arias": {
            "stepper": {
                "start": "您在步骤",
                "end": "之间"
            },
            "back_button": "返回",
            "cover_selection": "封面编号",
            "image_to_load": "上传图片",
            "image_load": "正在上传图片",
            "loaded_image": "已上传的图片",
            "exampleDialogTitle": "图片示例",
            "next": "下一个",
            "prev": "上一个",
            "fill_requironments": "",
            "name_validation": "名称验证"
        },
        "please_upload_image": "请上传图片",
        "general": {
            "next": "下一个",
            "pageTitle": "书籍创建",
            "try_again": "重试"
        },
        "error": {
            "error_upload": "更新图片时出现问题",
            "many_faces": "请上传一张有一张人脸的图片"
        },
        "stage1": {
            "image_is_here": "在这里上传图片",
            "image_desc_1": "要上传图片，请将其拖入",
            "image_desc_2": "屏幕或按橙色按钮。",
            "several_things": "一些重要的事情！",
            "browse_files": "浏览文件",
            "or": "或者",
            "several_things_cont": {
                "first": "1. 上传一个孩子的高质量照片，其中孩子位于中心...",
                "second": "2. 正确图片的示例"
            },
            "title": "让我们开始",
            "explain": "首先，上传图片，您可以拖动或单击按钮",
            "file_size": "最大尺寸：",
            "note": "图片质量更好，角度正确的话效果会更好",
            "example": "给我一个例子",
            "popup_bullets": {
                "bullet_one": "照片应该只有一个人脸，没有眼镜，假发，服装等",
                "bullet_two": "照片应该是正面向相机的角度，整个脸都能看到",
                "bullet_three": "照片需要眼睛睁开"
            },
            "popup_exit": "我明白",
        },
        "stage2": {
            "nice_photo": "哦！这是一张很好的照片！",
            "continue_text": "要继续，我们需要一些细节",
            "name_validation_1": "是",
            "name_validation_2_male": "孩子的名字吗？",
            "name_validation_2_female": "孩子的名字吗？",
            "yes": "是",
            "no": "不是",
            "fields": {
                "name_male": "名称",
                "name_female": "名称",
                "age_hero": "年龄",
                "gender": {
                    "male": "男性",
                    "female": "女性"
                },
                "age": "年龄：",
                "apply": "保存",
                "email": "电子邮件",
                "phone": "电话号码",
                "email_validation": "错误的电子邮件格式",
                "sex": "性别",
                "age_validation": "年龄必须在2到21岁之间"
            },
            "terms": "同意",
            "terms_part_2": "使用条款",
            terms_link: "https://mebook.ai/en/refund_returns/"
        },
        "stage3": {
            "kids_name_male": "我们的英雄名字：",
            "kids_name_female": "我们的英雄名字：",
            "kids_age_female": "她今年：",
            "kids_age": "他今年：",
            "hairColor": "发色",
            "eyeColor": "眼睛颜色",
            "skinColor": "肤色",
            "hairStyle": "发型"
        },
        "stage4": {
            "title": "选择一个主题",
            "buttonText": "获取个性化"
        },
        "loading_texts": {
            "text1": "闪耀的想象力...",
            "subtext1": "只差几个魔幻时刻了！",
            "text2": "释放创造力...",
            "subtext2": "您的个性化冒险正在酝酿中！",
            "text3": "拥抱期待...",
            "subtext3": "您的AI书籍正在制作中！",
            "text4": "沉浸在奇迹中...",
            "subtext4": "您的个性化冒险正在酝酿中！",
            "text5": "即将迎来引人入胜的时刻...",
            "subtext5": "我们正在为您打造一部故事！"
        },
        "click_me": "点击我",
        "end_process": {
            "main_text": "太棒了！选择您想要的选项之一 \n" + "为您的书籍封面设定",
            "buttonText": "结账",
            "choose_cover_pdf": "数字电子书",
            "choose_cover_hard": "精装书",
            "p_description_pdf": "高质量的数字电子书，以PDF格式精心设计，随时随地可读。",
            "p_description_hard": "印刷书籍，硬皮精装，高品质印刷，现代设计",
            "price_pdf": "$15",
            "price_book": "$39"
        },
        "stage5": {
            "banned_title": "我们致力于确保所有客户都能享受到这一卓越服务。为此，我们实施了每天最多生成三次书籍的限制。",
            "desc": "但是，您仍然可以选择以前生成的书籍以方便您的使用。"
        },
        "do_not_refresh_the_page": "请勿刷新页面",
        "example_of_first_page": "* 第一页的示例",
        "continue_editing_book": "要继续编辑书籍，请选择以下选项之一",
        "choose": "选择",
        hard_image: 'https://mebook.ai/wp-content/uploads/2023/09/%D7%98%D7%91%D7%90%D7%9C%D7%98-1.png',
        pdf_image: 'https://mebook.ai/wp-content/uploads/2023/09/U5XQa1yO5-1-e1694077013211-1024x1021.png',
    }
};

export default ch;
