import mixpanel from "mixpanel-browser";

export function trackEvent(eventName) {
    try {
        window.gtag("event", eventName, {
            event_category: "frame",
            event_label: eventName,
        });
        window.fbq("track", eventName, {
            content_name: "frame",
        });
    } catch (e) {
        console.log(e);
    }
}

export function identifyUser(phone) {
    try {
        mixpanel.identify(phone);

        mixpanel.people.set({
            "$phone": phone
        });
    } catch (e) {
        console.log(e);
    }
}

export function trackMixEvent(eventName, subEvent, bookId = "", message = "") {
    try {
        mixpanel.track(eventName, {
            "subEvent": subEvent,
            "bookId": bookId,
            "message": message
        });
    } catch (e) {
        console.log(e);
    }
}
