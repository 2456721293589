import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import styled from "styled-components";
import exit from "../../images/exit.svg";
import FocusTrap from "focus-trap-react";
import { chooseLang } from "../../../common/chooseLang";

const WraperStyled = styled.div`
    &#modal-container {
        position: fixed;
        display: table;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        transform: scale(0);
        z-index: 21;

        &.two {
            transform: scale(1);

            .modal-background {
                //background: rgba(0, 0, 0, .0);
                animation: .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

                .modal {
                    opacity: 0;
                    animation: scaleUp .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
                }
            }

            + #root {
                animation: scaleBack .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
            }

            &.out {
                animation: quickScaleDown 0s .5s linear forwards;

                .modal-background {
                    animation: .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

                    .modal {
                        animation: scaleDown .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
                    }
                }

                + #root {
                    animation: scaleForward .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
                }
            }
        }

        .modal-background {
            display: table-cell;
            background: rgba(0, 0, 0, .4);
            text-align: center;
            vertical-align: middle;

            .modal {
                background: white;
                display: inline-block;
                position: relative;

                padding: 25px;
                border: 1px solid #b5b5b5;
                border-radius: 15px;

                @media (max-width: 480px) {
                    border: none;
                    border-radius: 0;
                    position: fixed;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    padding: 39px 25px 10px;
                }
            }
        }
    }

    .exit_popup {
        cursor: pointer;
        position: absolute;
        right: 36px;
        top: 21px;

        img {
            width: 25px;
        }

        @media (max-width: 467px) {
            right: 7px;
            top: 10px;
        }
    }

    &#modal-container .modal-background .modal {
        @media (max-width: 1130px) {
            position: fixed;
            bottom: 0;
            width: 100%;
            left: 0;
            right: 0;
            border-radius: 0;
            box-sizing: border-box;
            top: inherit;
            //box-shadow: 1px -3px 16px 2px rgba(0,0,0,0.75);
            border-top: 1px solid #d2d2d2;
        }
    }
`;
const ModalRegular = ({isOpen, onClose, children, lang, closeModal, backGroundClose, ariaTitle, role}) => {
    const closeButtonRef = useRef(null);

    const [isHanled, s_isHanled] = useState(false);
    const [classes, s_classes] = useState("two out");

    const langData = chooseLang(lang);

    useEffect(() => {
        document.body.classList.add('modal-active');
        s_isHanled(true);
        s_classes('two');
    }, []);

    useEffect(() => {
        if (closeButtonRef.current) {
            closeButtonRef.current.focus();
        }
    }, [closeButtonRef.current]);

    useEffect(() => {
        if (closeModal) {
            onCloseHandle();
        }
    }, [closeModal]);

    const onCloseHandle = (e, isCross) => {
        let toClose = false;
        if (isCross) {
            toClose = true;
        }
        if (!toClose && backGroundClose) {
            toClose = true;
        }

        if (toClose) {
            s_classes('two out');
            setTimeout(() => {
                document.body.classList.remove('modal-active');
                s_isHanled(false);
                onClose();
            }, 700);
        }
    }

    return ReactDOM.createPortal(
        <WraperStyled
            onKeyDown={(e) => {
                if (e.key === "Escape") {
                    onCloseHandle(e, true);
                }
            }}
            tabIndex={0}
            role={role || "dialog"}
            aria-modal="true"
            aria-label={ariaTitle}
            id="modal-container" className={`lang-${lang} ${classes}`}>
            {isHanled && (
                <div className="modal-background" onClick={onCloseHandle}>
                    <FocusTrap>
                        <div className="modal">
                            <div
                                role='button'
                                aria-label={langData?.arias?.close}
                                tabIndex={"0"}
                                ref={closeButtonRef}
                                onKeyDown={(e) => {
                                    if (e.key === " " || e.key === "Enter") {
                                        onCloseHandle(e, true);
                                    }
                                }}
                                className="exit_popup"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onCloseHandle(e, true);
                                }}
                            >
                                <img src={exit} alt="exit"/>
                            </div>
                            {children}
                        </div>
                    </FocusTrap>
                </div>
            )}
        </WraperStyled>,
        document.getElementById('modal-container-w')
    );
};

export default ModalRegular;
