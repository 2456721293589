import React from 'react';
import styled from "styled-components";
import {ArrowLeftSvg, VArrowSvg, XArrowSvg} from "../../../svg";
import CONSTS from "../../../consts";
import Button from "../Button";
import {BadaraStyled, ErorrTitleStyled, ImageIconStyled, ImagesStyled} from "./CommonUploadImages";

const WrapperStyled = styled.div`

`;

const UploadImageError = ({errorCode, langData, onClick}) => {

    const errorMessage = langData.image_errors[errorCode];
    const errorLabel = langData.image_error_labels[errorCode];
    const normalLabel = langData.image_error_labels['normal'];

    const errorImage = CONSTS.error_images_links[errorCode];
    const normalImage = CONSTS.error_images_links["normal"];

    return (
        <WrapperStyled>
            <ImageIconStyled>
                <img src="https://print.mebook.ai/static/frame/errors/dca823a0a4bff65b9fa727ee2a0fe193.png?width=200"
                     alt="alert"/>
            </ImageIconStyled>

            <ErorrTitleStyled>
                {errorMessage}
            </ErorrTitleStyled>

            <ImagesStyled>
                <div>
                    <BadaraStyled>
                        <XArrowSvg/>
                    </BadaraStyled>
                    <img src={errorImage} alt=""/>
                    <p>{errorLabel}</p>
                </div>
                <div className="lllizcsr">
                    <ArrowLeftSvg/>
                </div>
                <div>
                    <BadaraStyled className="norm">
                        <VArrowSvg/>
                    </BadaraStyled>
                    <img src={normalImage} alt=""/>
                    <p>{normalLabel}</p>
                </div>
            </ImagesStyled>

            <Button
                onClick={onClick}
                className="next-button try-again"
            >
                {langData.general.try_again}
            </Button>
        </WrapperStyled>
    );
};

export default UploadImageError;