import React from 'react';
import styled from "styled-components";
import coverWizard from "../images/coverWizard.svg";

const Wrapper = styled.div`
    text-align: center;
    font-size: 20px;
    padding: 10px;
    
    img{
        width: 150px;
    }
`;

const NotActive = () => {
    return (
        <Wrapper dir="rtl">
            <p>לקוחות יקרים,</p>
            <p></p>
            <p>המערכת שלנו בשיפוצים קלים ולכן לא תהיה זמינה באופן זמני.</p>
            <p></p>
            <p>אנחנו משתדרגים כדי לשפר לכם את השירות!</p>
            <p></p>
            <p>נחזור לפעילות בקרוב. תודה על ההבנה והסבלנות 😊</p>
            <p>צוות מיבוק</p>
            <img src={coverWizard} alt="wizard"/>
        </Wrapper>
    );
};

export default NotActive;