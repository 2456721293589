import React, {useEffect, useState} from 'react';
import {motion} from "framer-motion";

const HarlemShake = ({children, noShake}) => {
    const [isShaking, setIsShaking] = useState(false);

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsVisible(true);
        }, 1000);
        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsShaking(prevState => !prevState);
        }, 4000);
        return () => clearInterval(interval);
    }, []);

    const shakeMe = isShaking && !noShake;

    return (
        <motion.div
            className="element"
            initial={{opacity: 0, scale: 0.5}}
            animate={{opacity: 1, scale: 1, x: shakeMe ? [-5, 5, -5, 5, -5, 0] : 0}}
            transition={{duration: 0.5}}
        >
            {children}
        </motion.div>
    );
};

export default HarlemShake;