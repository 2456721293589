import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {transformImage} from "../../../common/utils";
import BookCoverPrev from "../BookCoverPrev";
import MediaBookPage from "./MediaBookPage";

const WrapperStyled = styled.div`
    height: 365px;
    width: 727px;

    &.ltr {
        .book {
            transition: opacity 0.4s 0.2s;
            perspective: 250vw;
        }

        .page {
            //background-color: #111111;
            float: left;
            margin-bottom: 0.5em;
            background: left top no-repeat;
            background-size: cover;
            background-color: #fff;
            box-shadow: 1px 2px 3px 0 rgba(22, 18, 8, 0.20);
        }

        .page:nth-child(even) {
            clear: both;
        }

        .book .pages {
            //width: 946px;
            //height: 473px;
            position: relative;
            transform-style: preserve-3d;
            backface-visibility: hidden;
            border-radius: 4px;
            margin: auto;
            right: 26%;
            transition: right 0.5s ease-in-out;
        }

        .book .page {
            float: none;
            clear: none;
            margin: 0;
            position: absolute;
            top: 0;
            width: 363px;
            height: 363px;
            transform-origin: 0 0;
            transition: transform 1.4s;
            backface-visibility: hidden;
            transform-style: preserve-3d;
            cursor: pointer;
            user-select: none;
            //background-color: #f0f0f0;
        }

        .book .page:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0);
            transition: background 0.7s;
            z-index: 2;
        }

        .book .page:nth-child(odd) {
            pointer-events: all;
            transform: rotateY(0deg);
            right: 0;
            border-radius: 0 4px 4px 0;
            background-image: linear-gradient(to right, rgba(0, 0, 0, .15) 0%, rgba(0, 0, 0, 0) 10%);
        }

        .book .page:nth-child(odd):hover {
            transform: rotateY(-15deg);
        }

        .book .page:nth-child(odd):hover:before {
            background: rgba(0, 0, 0, 0.03);
        }

        .book .page:nth-child(odd):before {
            background: rgba(0, 0, 0, 0);
        }

        .book .page:nth-child(even) {
            pointer-events: none;
            transform: rotateY(180deg);
            transform-origin: 100% 0;
            left: 0;
            border-radius: 4px 0 0 4px;
            //border-color: black;
            //background-image: linear-gradient(to left, rgba(0, 0, 0, .12) 0%, rgba(0, 0, 0, 0) 10%);
        }

        .book .page:nth-child(even):before {
            background: rgba(0, 0, 0, 0.2);
        }

        .book .page.grabbing {
            transition: none;
        }

        .book .page.flipped:nth-child(odd) {
            pointer-events: none;
            transform: rotateY(-180deg);
        }

        .book .page.flipped:nth-child(odd):before {
            background: rgba(0, 0, 0, 0.2);
        }

        .book .page.flipped:nth-child(even) {
            pointer-events: all;
            transform: rotateY(0deg);
        }

        .book .page.flipped:nth-child(even):hover {
            transform: rotateY(15deg);
        }

        .book .page.flipped:nth-child(even):hover:before {
            background: rgba(0, 0, 0, 0.03);
        }

        .book .page.flipped:nth-child(even):before {
            background: rgba(0, 0, 0, 0);
        }

        .page:nth-child(odd) {
            background-position: right top;
        }

        img {
            width: 100%;
        }

    }

    &.rtl {
        .book {
            transition: opacity 0.4s 0.2s;
            perspective: 250vw;
        }

        .page {
            float: left;
            margin-bottom: 0.5em;
            background: left top no-repeat;
            background-size: cover;
            background-color: #fff;
            box-shadow: 1px 2px 3px 0 rgba(22, 18, 8, 0.20);
        }

        .page:nth-child(even) {
            clear: both;
        }

        .book .pages {
            //width: 727px;
            //height: 365px;
            //width: 727px;
            position: relative;
            transform-style: preserve-3d;
            backface-visibility: hidden;
            border-radius: 4px;
            margin: auto;
            left: 24%;
            transition: left 0.5s ease-in-out;
        }

        .book .page {
            float: none;
            clear: none;
            margin: 0;
            position: absolute;
            top: 0;
            width: 363px;
            height: 363px;
            transform-origin: 0 0;
            transition: transform 1.4s;
            backface-visibility: hidden;
            transform-style: preserve-3d;
            cursor: pointer;
            user-select: none;
            //background-color: #f0f0f0;
        }

        .book .page:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0);
            transition: background 0.7s;
            z-index: 2;
        }

        .book .page:nth-child(odd) {
            pointer-events: all;
            transform: rotateY(0deg);
            transform-origin: right;
            //right: 0;
            left: 0;
            border-radius: 4px 0 0 4px;
            background-image: linear-gradient(to left, rgba(0, 0, 0, .15) 0%, rgba(0, 0, 0, 0) 10%);
        }

        .book .page:nth-child(odd):hover {
            transform: rotateY(15deg);
            //transform: rotate3d(0, -1, 0, -16deg);
            transform-origin: right;
        }

        .book .page:nth-child(odd):hover:before {
            background: rgba(0, 0, 0, 0.03);
        }

        .book .page:nth-child(odd):before {
            background: rgba(0, 0, 0, 0);
        }

        .book .page:nth-child(even) {
            pointer-events: none;
            transform: rotateY(-180deg);
            transform-origin: 0 100%;
            //left: 0;
            right: 0;
            border-radius: 0 4px 4px 0;
            //border-color: black;
            //background-image: linear-gradient(to left, rgba(0, 0, 0, .12) 0%, rgba(0, 0, 0, 0) 10%);
        }

        .book .page:nth-child(even):before {
            background: rgba(0, 0, 0, 0.2);
        }

        .book .page.grabbing {
            transition: none;
        }

        .book .page.flipped:nth-child(odd) {
            pointer-events: none;
            transform: rotateY(180deg);
        }

        .book .page.flipped:nth-child(odd):before {
            background: rgba(0, 0, 0, 0.2);
        }

        .book .page.flipped:nth-child(even) {
            pointer-events: all;
            transform: rotateY(0deg);
        }

        .book .page.flipped:nth-child(even):hover {
            transform: rotateY(-15deg);
        }

        .book .page.flipped:nth-child(even):hover:before {
            background: rgba(0, 0, 0, 0.03);
        }

        .book .page.flipped:nth-child(even):before {
            background: rgba(0, 0, 0, 0);
        }

        .page:nth-child(odd) {
            background-position: left top;
        }

        img {
            width: 100%;
        }
    }

    @keyframes moveRight {
        from {
            left: 0;
        }
        to {
            left: 200px; /* Adjust the value based on how far you want to move the element */
        }
    }

    @media (max-width: 767px) {
        overflow: hidden;
        width: 100%;

        &.rtl, &.ltr {
            .book {
                .pages {
                    width: 100%;

                    .page {
                        width: 50%;
                    }
                }
            }
        }
    }


`;

const PagesListWrapperStyled = styled.div`
    .book-prev-component {
        .kkop {
            width: 100% !important;
            height: 100% !important;
        }
    }
`;

const TxetOageStyled = styled.div`
    height: 100%;
    position: relative;

    p {
        text-align: center;
        margin: auto;
        display: grid;
        align-content: center;
        font-size: 14px;
        line-height: 1.3;
        white-space: pre-line;
        width: 60%;
        height: 100%;
    }
`;

const ExampleOfThePage = styled.div`
    position: absolute;
    top: 2px;
    width: 100%;
    font-size: 15px;
    font-style: italic;


    @media (max-width: 480px) {
        font-size: 11px;
    }
`;


export const DEFAULT_WIDTH = 365;

const BookPreview = ({book, bookCoverData, lang, imagesToPreview, width, langData}) => {
    const pikaRefs = [];

    const [isOpen, s_isOpen] = useState(false);

    useEffect(() => {
        if(isOpen){
            pikaRefs?.[1]?.current?.focus();
        }
        if(!isOpen){
            pikaRefs?.[0]?.current?.focus();
        }
    }, [isOpen]);

    const bookData = [{img: bookCoverData?.img}];
    book.forEach(p => {
        bookData.push({imgs: [p.us_u1, p.us_u2, p.us_u3, p.us_u4]});
        bookData.push({text: p.text});
    });

    bookData.forEach((pp, inx) => {
        if (inx % 2 === 0) {
            pp.zIndex = bookData.length - inx;
        }
    });

    const pagesStyle = {
        width,
        height: width / 2 + 1.5
    };
    if (isOpen) {
        if (lang === "he") {
            pagesStyle.left = 0;
        } else {
            pagesStyle.right = 0;
        }
    }

    const wrapperStyle = {
        height: width / 2 + 1.5
    };

    let wrapperClasses = `lang-${lang}`;
    if (lang === 'he') {
        wrapperClasses += ' rtl';
    } else {
        wrapperClasses += ' ltr';
    }

    const pageDim = parseInt(width / 2, 10);

    return (
        <WrapperStyled className={wrapperClasses} style={wrapperStyle}>
            <div className="book">
                <PagesListWrapperStyled role="radiogroup" className='pages' style={pagesStyle}>
                    {bookData.map((page, idx) => {
                        const pageNum = idx + 1;
                        const style = {width: pageDim, height: pageDim};
                        if (page.zIndex !== undefined) {
                            style.zIndex = page.zIndex;
                        }

                        pikaRefs[idx] = React.createRef();

                        const isCover = idx === 0;
                        const isImg = page.imgs && !isCover;
                        const isText = page.text && !isCover;
                        const isSecondPage = idx === 1;

                        const transformedImage = transformImage(page.img, 'w_1500');

                        let id = "";
                        if (isCover) {
                            id = 'book-preview-cover-id';
                        }
                        if (isSecondPage) {
                            id = 'book-preview-second-page-id'
                        }

                        let pageFontSize = 18;
                        if (width < DEFAULT_WIDTH + 20) {
                            const percent = width / DEFAULT_WIDTH * 100;
                            pageFontSize = parseInt(pageFontSize - (pageFontSize * percent / 100), 10);
                            if (pageFontSize < 8) {
                                pageFontSize = 8;
                            }
                            if (!pageFontSize) {
                                pageFontSize = 8;
                            }
                        }

                        let tabIndexVal = "-1";
                        if (isOpen) {
                            tabIndexVal = "0";
                        } else {
                            if (idx === 0) {
                                tabIndexVal = "0";
                            }
                        }


                        let classes = "page";
                        let role = 'radio';

                        if(isOpen && idx === 0){
                            tabIndexVal = "-1";
                        }

                        let ariaLabel = langData?.arias?.preview_book?.cover;
                        if(isImg){
                            ariaLabel = langData?.arias?.preview_book?.image;
                        }
                        if(isText){
                            ariaLabel = langData?.arias?.preview_book?.text;
                            tabIndexVal = "-1";
                            role = '';

                        }
                        if(isCover){
                            role = 'button';
                        }

                        return (
                            <div
                                aria-expanded={isCover}
                                role={role}
                                ref={pikaRefs[idx]}
                                onKeyDown={(event) => {
                                    switch (event.key) {
                                        case ' ': // space
                                        case 'Enter': // space
                                            pikaRefs?.[idx]?.current?.click();
                                            break;
                                        default:
                                            break;
                                    }
                                }}
                                aria-label={ariaLabel}
                                onClick={(e)=>{
                                    if (isOpen && idx === 1) {
                                        s_isOpen(false);
                                    } else {
                                        s_isOpen(true);
                                    }
                                    if (idx === bookData.length - 1) {
                                        return;
                                    }
                                    if (pageNum % 2 === 0) {
                                        pikaRefs[idx].current.classList.remove('flipped');
                                        pikaRefs[idx].current.previousElementSibling?.classList?.remove('flipped');
                                    } else {
                                        pikaRefs[idx].current.classList.add('flipped');
                                        pikaRefs[idx].current.nextElementSibling?.classList?.add('flipped');
                                    }
                                }}
                                id={id}
                                tabIndex={tabIndexVal}
                                className={classes}
                                key={idx}
                                style={style}
                            >
                                {isCover && (
                                    <BookCoverPrev
                                        parentWidth={width}
                                        imageUrl={transformedImage}
                                        lang={lang}
                                        text={bookCoverData.text}
                                        coverDesign={bookCoverData.coverDesign}
                                        index={3}
                                        isSelected={false}
                                        partOfBook={true}
                                    />)}


                                {isImg && (
                                    <MediaBookPage
                                        isShown={isOpen}
                                        lang={lang}
                                        imgs={page.imgs}
                                        langData={langData}
                                    />
                                )}
                                {isText && (
                                    <TxetOageStyled>
                                        <ExampleOfThePage>
                                            {langData?.example_of_first_page}
                                        </ExampleOfThePage>
                                        <p style={{fontSize: pageFontSize}}>{page.text}</p>
                                    </TxetOageStyled>
                                )}
                            </div>)
                    })}
                </PagesListWrapperStyled>
            </div>
        </WrapperStyled>);
};

export default BookPreview;