import { Tooltip } from "react-tooltip";
import icon from "../../images/lightbrown.png";
import React, { useEffect, useRef, useState } from "react";

function ParamContainer({ attribute, lang, currentLang, progress, updateProgress }) {
    const labelRefs = [];
    const [onFocusValue, s_onFocusValue] = useState(0);
    const [selectedInnerIndex, s_selectedInnerIndex] = useState(0);

    const key = (attribute.key ? attribute.key : "hairStyle");
    const options = (attribute.options ? attribute.options : attribute);

    const checkedValue = (name, event) => {
        updateProgress(name, event.target.value);
    };

    useEffect(() => {
        if (selectedInnerIndex !== -1) {
            labelRefs[selectedInnerIndex].current.focus();
        }
    }, [selectedInnerIndex]);

    const selectedIndex = options?.findIndex(obj => obj.value === progress[key]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (onFocusValue && options) {
                switch (event.key) {
                    case "ArrowLeft":
                    case "ArrowDown":
                        if (options[selectedInnerIndex + 1]?.value) {
                            updateProgress(key, options[selectedInnerIndex + 1].value);
                            s_selectedInnerIndex(selectedInnerIndex + 1);
                        }
                        break;
                    case "ArrowRight":
                    case "ArrowUp":
                        if (options[selectedInnerIndex - 1]?.value) {
                            updateProgress(key, options[selectedInnerIndex - 1].value);
                            s_selectedInnerIndex(selectedInnerIndex - 1);
                        }
                        break;
                    case " ": // space
                    case "Enter": // space
                        updateProgress(key, onFocusValue.value);
                        break;
                    default:
                        break;
                }
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [onFocusValue, selectedIndex]);

    return (
        <div
            role="radiogroup"
            aria-label={lang.stage3[key]}
            className={ `${ key } param_container` }>
            <Tooltip id="params-tooltip" place="bottom" className="pizeda"/>
            <div className="title">{ lang.stage3[key] }</div>
            <div
                className="inner_params">
                { options.map((optionMap, innerIndex) => {
                    labelRefs[innerIndex] = React.createRef();

                    const isChecked = progress[key] === optionMap.value;
                    let isFirstChecked = false;
                    if (!progress[key] && innerIndex === 0) {
                        isFirstChecked = true;
                    }

                    let tabIndexValue = "-1";
                    if (isChecked || isFirstChecked) {
                        tabIndexValue = "0";
                    }

                    return (
                        <div
                            ref={ labelRefs[innerIndex] }
                            onFocus={ () => {
                                s_onFocusValue(optionMap);
                                s_selectedInnerIndex(innerIndex);
                            } }
                            onBlur={ () => {
                                s_onFocusValue(null);
                                s_selectedInnerIndex(-1);
                            } }
                            tabIndex={ tabIndexValue }
                            role="radio"
                            aria-label={ optionMap?.labels?.[currentLang] }
                            aria-checked={ progress[key] === optionMap.value }
                            data-tooltip-id="params-tooltip"
                            data-tooltip-content={ optionMap?.labels?.[currentLang] }
                            className="paramField"
                            key={ innerIndex }
                        >
                            <input type="radio" value={ optionMap.value } id={ key + "" + optionMap.value } name={ key }
                                   checked={ progress[key] === optionMap.value }
                                   onChange={ (event) => checkedValue(key, event) }></input>
                            <label
                                className="image" htmlFor={ key + "" + optionMap.value }><img
                                src={ optionMap.imageUrl ? optionMap.imageUrl : icon } alt={optionMap?.labels?.[currentLang]}/></label>
                        </div>
                    );
                }) }
            </div>
        </div>
    );

}

export default ParamContainer;
