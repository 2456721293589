const eng = () => {
    return {
        image_errors: {
            6000: "Could not analyze face image",
            6001: "Image contains no faces",
            6002: "Image contains more than one face",
            6003: "Face is occluded",
            6004: "Eyes are closed",
            6005: "Wearing sunglasses",
            6006: "Face is tilted",
            6007: "Face is too small",
        },
        image_error_labels: {
            6000: "Could not analyze face image",
            6001: "No face",
            6002: "One child only",
            6003: "Face is occluded",
            6004: "Eyes are closed",
            6005: "Wearing sunglasses",
            6006: "Straight-on view",
            6007: "Face is too small",
            "normal": "Clear and well-lit"
        },
        arias: {
            stepper: {
                start: 'You are on step',
                end: 'of'
            },
            back_button: 'Back',
            cover_selection: 'Cover number',
            image_to_load: 'Upload image',
            image_load: 'Uploading image',
            loaded_image: 'Uploaded image',
            exampleDialogTitle: 'Image example',
            next: 'Next',
            prev: 'Previous',
            fill_requironments: "",
            name_validation: 'Name validation'
        },
        please_upload_image: "Please upload the image",
        general: {
            next: 'Next',
            pageTitle: 'Book creation',
            try_again: "Try again"
        },
        error: {
            error_upload: 'There was a problem to update the image',
            many_faces: "Please upload image with one face",
        },
        stage1: {
            image_is_here: "Upload a picture here",
            image_desc_1: "Upload a clear photo of your child to make",
            image_desc_2: " them the star of their own story!",
            several_things: "Tips for the Perfect Hero Shot:",
            browse_files: "Browse files",
            several_things_cont: {
                "first": "Upload a high-quality photo of the child in which they are in the center",
                "second": "2. Examples of a correct image"
            },
            title: 'Start Your Child\'s Adventure!',
            explain: 'First, upload the picture, you can drag or click the button',
            file_size: 'Max size:',
            note: 'Picture in better quality and with a right angle will make a better result',
            example: 'Show me an example',
            popup_bullets: {
                bullet_one: 'The photo should have one face, without glasses, wigs, costumes, etc',
                bullet_two: 'The photo should be taken with the face in front of the camera at an angle where the whole face can be seen',
                bullet_three: 'The picture needs to be with eyes open',
            },
            popup_exit: 'I Understand',
            cropper_text: "Center your child's face in the frame for the best results"
        },
        stage2: {
            nice_photo: 'Oh! this is a nice photo!',
            continue_text: 'Let\'s Personalize Your Child\'s Adventure!',
            name_validation_1: "Is",
            name_validation_2_male: "first name of child?",
            name_validation_2_female: "first name of child?",
            yes: 'Yes',
            no: 'No',
            fields: {
                name_male: 'Child\'s Name',
                name_female: 'Child\'s Name',
                age_hero: 'Age',
                gender: {
                    male: 'Male',
                    female: 'Female'
                },
                age: 'Age:',
                apply: 'Save',
                email: 'Email',
                phone: 'Phone number',
                email_validation: 'Wrong email format',
                sex: 'Gender',
                age_validation: 'Age must be',
            },
            terms: "I agree to the story magic ",
            terms_part_2: "(terms and privacy)",
            terms_link: "https://mebook.ai/en/refund_returns/",
            terms_sms: "I agree to get mailings via text messages and/or e-mail"
        },
        stage3: {
            kids_name_male: 'Our Hero\'s Nname: ',
            kids_name_female: 'Our hero name is: ',
            kids_age_female: 'And she is: ',
            kids_age: 'And he is: ',
            hairColor: 'Hair Color',
            eyeColor: 'Eye Color',
            skinColor: 'Skin Tone',
            hairStyle: 'Hair Style'
        }, stage4: {
            title: 'Choose your story',
            buttonText: 'Let The Magic Begin',
        },
        loading_texts: {
            text1: 'Sparkling imagination...',
            subtext1: 'Just a few magical moments away!',
            text2: 'Unleashing creativity...',
            subtext2: 'Your personalized adventure is brewing!',
            text3: 'Embrace the anticipation...',
            subtext3: 'Your AI book is in the making!',
            text4: 'Immerse in wonder...',
            subtext4: 'Your personalized adventure is brewing!',
            text5: 'Captivating moments ahead...',
            subtext5: 'We\'re crafting a story just for you!',
        },
        click_me: 'Click me',
        end_process: {
            main_text: 'Amazing! Pick the one of the options you want \n' +
                'to set for your book cover',
            buttonText: 'Checkout',
            choose_cover_pdf: 'Digital e-book',
            choose_cover_hard: 'Hardcover book',
            p_description_pdf: 'A high-quality digital e-book in PDF format, exquisitely designed and readable from anywhere at any time.',
            p_description_hard: 'Printed book with a hardcover and high-quality printing, featuring a modern design',
            price_pdf: '$15',
            price_book: '$39'
        },
        stage5:{
            banned_title: "We are dedicated to ensuring that this exceptional service is accessible to all our customers. In our efforts to achieve this, we have implemented a limitation on book generation to three times per day.",
            desc: "However, you retain the option to select from the previously generated books for your convenience."
        },
        do_not_refresh_the_page: 'Please do not refresh the page',
        example_of_first_page: '* Example of first page',
        continue_editing_book: 'To continue editing the book, choose one of the following options',
        choose: 'Choose',
        pdf_image: 'https://mebook.ai/wp-content/uploads/2023/09/U5XQa1yO5-1-e1694077013211-1024x1021.png',
        hard_image: 'https://mebook.ai/wp-content/uploads/2023/09/%D7%98%D7%91%D7%90%D7%9C%D7%98-1.png',
        past_books:"Past books",
        terms_modal_h: "This agreement outlines the terms and conditions governing purchases made through MeBook’s website and mobile applications. It also provides details regarding our returns and refunds policy.",
        terms_modal: [
            {
                title: "Placing Orders",
                p: "You may place orders for personalized children’s books through our website or mobile apps. All orders require submission of details about your child along with payment information. By placing an order, you confirm that all information provided is accurate."
            },
            {
                title: "Pricing and Payment",
                p: "The prices displayed include all applicable taxes and charges. Payment must be made in full at the time of purchase via credit card or any other payment option made available. MeBook reserves the right to update prices at any time."
            },
            {
                title: "Order Confirmation and Processing",
                p: "You will receive an order confirmation email with details of your purchase. Book creation and printing will begin as soon as payment is successfully processed. Estimated delivery timelines will be communicated in the order confirmation."
            },
            {
                title: "Shipping and Delivery",
                p: "We will ship your completed book to the address specified at checkout. Delivery timelines may vary based on your location. MeBook cannot be held responsible for delays caused by third-party delivery partners."
            },
            {
                title: "Returns and Refunds",
                p: "If your book is damaged during shipping or differs significantly from the preview provided, please contact us within 7 days of delivery to initiate a return. We will assess the damage or discrepancy claimed and may request photographic evidence." +
                    "If approved, you will be refunded the full purchase price once the book is returned to our fulfilment center. Return shipping costs will be reimbursed if the reason for return is due to damage or errors on our part."
            },
            {
                title: "Cancelling or Changing Orders",
                p: "Given the personalized nature of our books, orders cannot be cancelled or changed once book creation has begun. Please review order details carefully before placing your order."
            },
            {
                title: "Limitation of Liability",
                p: "MeBook will not be liable for any indirect, incidental, or consequential damages arising from the purchase or use of our products. Our maximum liability is limited to the purchase price of the book."
            },
            {
                title: "Contact Us",
                p: "If you have any questions or concerns regarding your order, please contact us at info@mebook.ai. We are committed to a fun, easy ordering experience and your complete satisfaction."
            }
        ],
        preebook_title: "Your books",
        preebook_subTitle: "Books below are not in cart",
        otp:{
            title: "Share your phone number",
            title_email: "Share your email",
            title_2: "SMS with the code is on the way",
            title_2_email: "Email with the code is on the way",
            code_is_invalid: "Code is invalid",
            change_phone_number: "Change phone number",
            send_again: "Resend",
        }
    };
};

export default eng;


// COULD_NOT_ANALYZE_FACE_IMAGE: {errorCode: 6000, message: 'could not analyze face image'},
// NO_FACES_IN_IMAGE: {errorCode: 6001, message: 'image contains no faces'},
// MORE_THAN_FACE_IN_IMAGE: {errorCode: 6002, message: 'image contains more than one face'},
// FACE_IS_OCCLUDED: {errorCode: 6003, message: 'face is occluded'},
// EYES_ARE_CLOSED: {errorCode: 6004, message: 'eys are close'},
// WEARING_SUNGLASSES: {errorCode: 6005, message: 'wearing sunglasses'},
// FACE_ARE_TILTED: {errorCode: 6006, message: 'face is tilted'},
// FACE_TOO_SMALL: {errorCode: 6007, message: 'face is too small'},
