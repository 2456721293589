import React, {useEffect, useRef} from 'react';
import {ButtonStyled} from "../commonStyles";

const Button = ({
                    children,
                    onClick,
                    className,
                    disabled,
                    focus,
                    tab = "0",
                    loading,
                    not_clickable,
                    dataTooltipId,
                    dataTooltipContent,
                    ariaLabel
                }) => {
    const buttonRef = useRef(null);

    useEffect(() => {
        if (focus) {
            if (buttonRef.current) {
                buttonRef.current.focus();
            }
        } else {
            if (buttonRef.current) {
                buttonRef.current.blur();
            }
        }
    }, [focus]);


    if (loading) {
        className += ' loading';
    }
    if (not_clickable) {
        className += ' not_clickable';
    }

    return (
        <ButtonStyled
            aria-label={ariaLabel}
            data-tooltip-id={dataTooltipId}
            data-tooltip-content={dataTooltipContent}
            tabIndex={tab}
            ref={buttonRef}
            onClick={onClick}
            className={className}
            disabled={disabled}
        >
            <span>{children}</span>
        </ButtonStyled>
    );
};

export default Button;